import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Input } from "antd";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import { Rate } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { Box, Modal } from "@mui/material";

const onChange: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Offcontent({ data }: { data: any[] }) {
  const [dataform, setDataform] = useState({
    name: "",
    userEmail: "",
    userPhonenumber: "",
    option: "",
    message: "",
    time: "",
    date: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      await axios.post("https://dimooraapi.onrender.com/ContactUs", dataform);
      setDataform({
        name: "",
        userEmail: "",
        userPhonenumber: "",
        option: "",
        message: "",
        time: "",
        date: "",
      });
      setOpen(true)
    } catch (err: any) {
      console.error(err.message);
      alert("Failed to submit. Please try again.");
    }
  };

  return (
    <>
      <div>
        <section className="gaps pb-5 pad">
          <Container>
            <Row>
              <Col sm={12} md={6} lg={8}>
                <div>
                  <Row>
                    <Col>
                      <div className="bg-2 mt-3">
                        <h5 className="off-txt-1">Details</h5>
                        <div className="d-flex gap-3">
                          <div className="d-flex justify-content-center align-items-center">
                            <i className="fi fi-rr-house-tree off-icon"></i>
                          </div>
                          <div>
                            <p className="off-txt-1">{data[0]?.title}</p>
                            <p className="off-txt-1">Type</p>
                            <p className="off-txt-2">{data[0]?.type}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mt-4 bg-2 mb-4">
                        <Row>
                          <Col md={12} sm={12}>
                            <div>
                              <h5 className="off-txt-1">Attribute:</h5>

                              <div className="pt-4">
                                <p className="off-txt-2">
                                  Starting Price:
                                  <span className="off-txt-3">
                                    {data[0]?.stratingPrice}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Developer:
                                  <span className="off-txt-3">
                                    {data[0]?.developer}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Location:
                                  <span className="off-txt-3">
                                    {data[0]?.location}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Property Type:
                                  <span className="off-txt-3">
                                    {" "}
                                    {data[0]?.type}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Unit Types:{" "}
                                  <span className="off-txt-3">
                                    {data[0]?.unittypes}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Sizes:
                                  <span className="off-txt-3">
                                    {data[0]?.size}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Number of Units:
                                  <span className="off-txt-3">
                                    {data[0]?.nounit}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Average Price:
                                  <span className="off-txt-3">
                                    {data[0]?.average}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Handover Date:
                                  <span className="off-txt-3">
                                    {" "}
                                    {data[0]?.handover}
                                  </span>
                                </p>
                                <p className="off-txt-2">
                                  Payment Plan:
                                  <span className="off-txt-3">
                                    {data[0]?.paymentplan}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={12} sm={12}>
                            <div>
                              <h5 className="off-txt-1">Amenities:</h5>
                              <div className="p-4">
                                {data[0]?.amenities.map((val: any, i: any) => (
                                  <li className="off-txt-2" key={i}>
                                    {val}
                                  </li>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="bg-2 mb-4">
                        <Row>
                          <Col md={12} sm={12}>
                            <div>
                              <h5 className="off-txt-1">Overview:</h5>
                              <p className="off-txt-2">{data[0]?.overview}</p>
                              <p className="off-txt-2">{data[0]?.para1}</p>

                              <p className="off-txt-2">{data[0]?.para2}</p>
                              <p className="off-txt-2">{data[0]?.para3}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="bg-2">
                        <Row>
                          <Col md={12} sm={12}>
                            <div>
                              <h5 className="off-txt-1">Key Features:</h5>
                              <div className="p-4">
                                {data[0]?.unitoptions && (
                                  <li className="off-txt-3">
                                    Unit Options:
                                    <span className="off-txt-2">
                                      {data[0]?.unitoptions}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.primelocation && (
                                  <li className="off-txt-3">
                                    Prime Location:
                                    <span className="off-txt-2">
                                      {data[0]?.primelocation}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.moderndesign && (
                                  <li className="off-txt-3">
                                    Modern Design:
                                    <span className="off-txt-2">
                                      {data[0]?.moderndesign}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.elegantinteriors && (
                                  <li className="off-txt-3">
                                    Elegant Interiors:
                                    <span className="off-txt-2">
                                      {data[0]?.elegantinteriors}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.outdoorliving && (
                                  <li className="off-txt-3">
                                    Outdoor Living:
                                    <span className="off-txt-2">
                                      {data[0]?.outdoorliving}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.familyfriendlyamenities && (
                                  <li className="off-txt-3">
                                    Family-Friendly Amenities:
                                    <span className="off-txt-2">
                                      {data[0]?.familyfriendlyamenities}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.sustainablelifestyle && (
                                  <li className="off-txt-3">
                                    Sustainable Lifestyle:
                                    <span className="off-txt-2">
                                      {data[0]?.sustainablelifestyle}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.apartmentoptions && (
                                  <li className="off-txt-3">
                                    Apartment Options:
                                    <span className="off-txt-2">
                                      {data[0]?.apartmentoptions}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.locationk && (
                                  <li className="off-txt-3">
                                    Location:
                                    <span className="off-txt-2">
                                      {data[0]?.locationk}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.amenitiesk && (
                                  <li className="off-txt-3">
                                    Amenities:
                                    <span className="off-txt-2">
                                      {data[0]?.amenitiesk}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.investmentpotential && (
                                  <li className="off-txt-3">
                                    Investment Potential:
                                    <span className="off-txt-2">
                                      {data[0]?.investmentpotential}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.communityfeatures && (
                                  <li className="off-txt-3">
                                    Community Features:
                                    <span className="off-txt-2">
                                      {data[0]?.communityfeatures}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.tillhandover && (
                                  <li className="off-txt-3">
                                    Till Handover:
                                    <span className="off-txt-2">
                                      {data[0]?.tillhandover}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.posthandover && (
                                  <li className="off-txt-3">
                                    Post-Handover:
                                    <span className="off-txt-2">
                                      {data[0]?.posthandover}
                                    </span>
                                  </li>
                                )}
                                {data[0]?.locationhigh && (
                                  <li className="off-txt-3">
                                    Locationhighlights:
                                    <span className="off-txt-2">
                                      {data[0]?.locationhigh}
                                    </span>
                                  </li>
                                )}
                              </div>

                              {/* Conditionally render the "Exclusive Launch Offer" title if data exists */}
                              {data[0]?.exclusivelaunchoffer && (
                                <div>
                                  <h5>Exclusive Launch Offer:</h5>
                                  <p>{data[0]?.Payment}</p>
                                </div>
                              )}

                              <div className="p-2">
                                <p>
                                  {data[0]?.title} is the perfect blend of
                                  modern luxury and active living, offering a
                                  vibrant community experience in one of Dubai’s
                                  most sought-after areas.
                                </p>

                                <p>
                                  We are more than happy to serve you! Thank you
                                  for choosing Dimoora Real Estate.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="bg-2 mt-4">
                        <h5>Details</h5>
                        <Row>
                          <Col md={12} sm={12}>
                            <div>
                              <p>
                                Price :{" "}
                                <span className="title">{data[0]?.prices}</span>
                              </p>
                            </div>
                          </Col>
                          <Col md={12} sm={12}>
                            <div>
                              <p>
                                Status:{" "}
                                <span className="title">{data[0]?.status}</span>{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="bg-2 mt-4">
                        <h5>Be the first to review {data[0]?.title}</h5>
                        <div className="d-flex gap-4">
                          <div>Rating</div>
                          <div>
                            <Rate className="rate" />
                          </div>
                        </div>
                        <Row className="mt-2">
                          <Col lg={6} md={12} sm={12}>
                            <div>
                              <label>Name</label>
                              <Input
                                className="inputs mt-2"
                                placeholder="name"
                              />
                            </div>
                          </Col>
                          <Col lg={6} md={12} sm={12}>
                            <div>
                              <label>Email</label>
                              <Input
                                className="inputs mt-2"
                                placeholder="email"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Checkbox onChange={onChange}>
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </Checkbox>

                        <div className="mt-2">
                          <label>Review</label>
                          <textarea
                            className="review-txt"
                            placeholder="Message"
                          ></textarea>
                        </div>

                        <div className="d-flex justify-content-center mt-3">
                          <Button className="btn-tour">Submit</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="bg-2 fix-contact-1">
                  <h5 className="off-txt-1">Get in touch</h5>

                  <div className="mt-3">
                    <Input
                      className="inputs"
                      placeholder="Date"
                      onChange={(e) =>
                        setDataform({ ...dataform, date: e.target.value })
                      }
                    />
                    <Input
                      className="inputs"
                      placeholder="Time"
                      onChange={(e) =>
                        setDataform({ ...dataform, time: e.target.value })
                      }
                    />
                    <Input
                      className="inputs"
                      placeholder="Name"
                      onChange={(e) =>
                        setDataform({ ...dataform, name: e.target.value })
                      }
                    />
                    <Input
                      className="inputs"
                      placeholder="Phone"
                      onChange={(e) =>
                        setDataform({
                          ...dataform,
                          userPhonenumber: e.target.value,
                        })
                      }
                    />
                    <Input
                      className="inputs"
                      placeholder="Email"
                      onChange={(e) =>
                        setDataform({ ...dataform, userEmail: e.target.value })
                      }
                    />
                    <textarea
                      placeholder="Message"
                      className="inputs-text"
                      onChange={(e) =>
                        setDataform({ ...dataform, message: e.target.value })
                      }
                    ></textarea>

                    <div className="d-flex justify-content-center mt-3">
                      <Button className="btn-tour" onClick={handleSubmit}>
                        Submit A Request
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Footer/> */}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="text-center">
            <img
              className="img-fluid"
              style={{ width: "100px" }}
              src="/assets/certificate.gif"
            />
          </div>
          <h3 className="text-center mb-0">
            Your message has been sent successfully.
          </h3>
          <h3 className="text-center mt-0">We will reach you out soon...</h3>
          <div className="text-end"><Link to="/"><Button className="continue" >Continue ..</Button></Link></div>
        </Box>
      </Modal>
      </div>
    </>
  );
}
