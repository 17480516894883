import React from "react";
import Offplan from "./Offplan";
import Footer from "../FOOTER/Footer";

export default function Projects() {
  return (
    <>
      <Offplan />
    </>
  );
}
