import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { useNavigate } from "react-router-dom";

const Help = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [activeButton, setActiveButton] = useState<"next" | "previous" | null>(
    null
  );

  const navigate = useNavigate();

  const totalDivs = 3;
  const visibleCount = 1;

  const divs = [
    {
      id: 1,
      imageUrl: "assets/home3.png",
      title: "Buy a property",
      para: "Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else.",
      description: "Find a home",
      route: "/find-home", // Route for "Find a home"
    },
    {
      id: 2,
      imageUrl: "assets/home2.png",
      title: "Sell a property",
      para: "No matter what path you take to sell your home, we can help you navigate a successful sale.",
      description: "Place an ad",
      route: "/place-ad", // Route for "Place an ad"
    },
    {
      id: 3,
      imageUrl: "assets/home1.png",
      title: "Rent a property",
      para: "We're creating a seamless online experience - from shopping on the largest rental network, to applying, to paying rent.",
      description: "Find a Rental",
      route: "/rent-property", // Route for "Find a Rental"
    },
  ];

  // Handle the "greater than" button click
  const handleNext = () => {
    setActiveButton("next");
    if (startIndex + visibleCount < totalDivs) {
      setStartIndex(startIndex + 1);
    }
    setTimeout(() => setActiveButton(null), 300);
  };

  // Handle the "less than" button click
  const handlePrevious = () => {
    setActiveButton("previous");
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
    setTimeout(() => setActiveButton(null), 300);
  };

  // Handle button click to navigate to the route
  const handleNavigate = (route: string) => {
    navigate(route); // Navigate to the desired route
  };

  return (
    <>
      <section className="py-5 section" id="help-section">
        <Container>
          <div className="pt-5">
            <div>
              <h2 className="txt-1">Experience Our Personalized Services</h2>
            </div>

            <Row className="pb-lg-5 pt-5 gap-row">
              <Col sm={12} md={12} lg={7}>
                <div className="d-flex justify-content-center align-items-center gaps-img">
                  {/* Less Than Button */}
                  <div className="less-div">
                    <button
                      className={`lessthan ${
                        activeButton === "previous" ? "active" : ""
                      }`}
                      onClick={handlePrevious}
                      disabled={startIndex === 0}
                    >
                      <i className="fi fi-rr-angle-small-left d-icons"></i>
                    </button>
                  </div>

                  {/* Display only the visible divs */}
                  <div className="d-flex product-div gap align-items-center">
                    {divs
                      .slice(startIndex, startIndex + visibleCount)
                      .map((div) => (
                        <div key={div.id} className="custom-div">
                          <div className="img-div-helps">
                            <img
                              src={div.imageUrl}
                              alt="img"
                              className="img-fluid help-img"
                            />
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* Greater Than Button */}
                  <div className="greater-div">
                    <button
                      className={`greaterthan ${
                        activeButton === "next" ? "active" : ""
                      }`}
                      onClick={handleNext}
                      disabled={startIndex + visibleCount >= totalDivs}
                    >
                      <i className="fi fi-rr-angle-small-right d-icons"></i>
                    </button>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={5}>
                {/* Display only the visible divs */}
                <div className="d-flex product-div-3 gap align-items-center">
                  {divs
                    .slice(startIndex, startIndex + visibleCount)
                    .map((div) => (
                      <div key={div.id} className="custom-div d-flex">
                        <div className="content-div text-right ms-3">
                          <h3 className="txt-7">{div.title}</h3>
                          <p className="txt-8-1">{div.para}</p>
                          <div className="help-btn-div">
                            <Button
                              className="btn-4"
                              onClick={() => handleNavigate(div.route)}
                            >
                              {div.description}
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Help;
