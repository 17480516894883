import React, { useState, useEffect } from "react";
import Nav from "./Navbar";
import Development from "./Development";
import Bestofweek from "./WeekProducts";
import Help from "./Help";
import Community from "./Community";
import Awards from "./Awards";
import Reviews from "./Reviews";
import Ourpartner from "./Ourpartner";
import Fixed from "./Fixed-ing";
import Footer from "../FOOTER/Footer";
import { Button } from "react-bootstrap";
import { Input, Select } from "antd";
import Achive from '../HOME/Achive';
import NavComponent from "../HOME/Navbar";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [hideBackground, setHideBackground] = useState(false);
  const [search,setSearch]=useState("")

const navigate=useNavigate()
  const handleSearch=()=>{
    navigate(`/allcities/${search}`)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setHideBackground(true);
      setShowVideo(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      < NavComponent />

      <section className="sections pb-5">
        <div
          className={`background-image ${hideBackground ? "fade-out" : ""}`}
        />

        <div className={`video-container ${showVideo ? "slide-in" : ""}`}>
          <video
            src="/assets/video.mp4"
            typeof="video/mp4"
            autoPlay
            loop
            muted
            className="video-content"
          />
        </div>

        {/* <video>
            <source src="/assets/video.mp4" type="video/mp4" className="video-content" />
          </video> */}

        <div className="content-1">
          <h1 className="v-txt">Find the Best Places to Be</h1>
        </div>
        <div className="content">
          <div className="content-combine">
            <div className="p-3 v-combine-image" >
              <div className="crs">
                <div>
                  <img
                    src="https://zuhaus.qodeinteractive.com/wp-content/uploads/2017/10/type-6-img-light.png"
                    alt="img" className="v-img"
                  />
                </div>
                <div className="v-img-txt">Buy</div>
              </div>
              <div>
                <div>
                  <img
                    src="https://zuhaus.qodeinteractive.com/wp-content/uploads/2017/10/type-4-img-light.png"
                    alt="img" className="v-img"
                  />
                </div>
                <div className="v-img-txt">Rent</div>
              </div>
              <div>
                <div>
                  <img
                    src="https://zuhaus.qodeinteractive.com/wp-content/uploads/2017/10/type-5-img-light.png"
                    alt="img" className="v-img"
                  />
                </div>
                <div className="v-img-txt">Commercial</div>
              </div>
              <div>
                <div>
                  <img
                    src="https://zuhaus.qodeinteractive.com/wp-content/uploads/2017/10/type-2-img-light.png"
                    alt="img" className="v-img"
                  />
                </div>
                <div className="v-img-txt">Sell</div>
              </div>
              <div>
                <div>
                  <img
                    src="https://zuhaus.qodeinteractive.com/wp-content/uploads/2017/10/type-4-img-light.png"
                    alt="img" className="v-img"
                  />
                </div>
                <div className="v-img-txt">Plots</div>
              </div>
            </div>
          </div>
          <div className="search-container">
          <Input placeholder="Select City" className="select"  onChange={(e)=>setSearch(e.target.value)}/>
            <Button className="btn-anis" onClick={handleSearch}>
              <span> Search</span>
            </Button>
          </div>
        </div>
      </section>

      <Development />
      <Bestofweek />
      <Fixed />
      <Help />
      <Community />
      <Achive/>
      <Awards />
      <Reviews />
      <Ourpartner />
      <Footer />
    </>
  );
};

export default Home;
