import React from "react";
import Slider from "react-slick"; // Import Slider from react-slick
import Container from "react-bootstrap/esm/Container";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Reviews() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Array of reviews or images data
  const divs = [
    {
      id: 1,
      img: "assets/Group 1691.png",
    },
    {
      id: 2,
      img: "assets/Group 1693.png",
    },
    {
      id: 3,
      img: "assets/Group 1694.png",
    },
    {
      id: 4,
      img: "assets/Group 1695.png",
    },


  ];

  return (
    <section className="section pt-5">
      <Container>
        <div>
          <h2 className="txt-1">
            People Love Living with Dimoora Real Estate
          </h2>
        </div>
        <div className="reviews">
          <div className="col-lg-6 col-md-12 col-sm-12 animation-2-combine">
            {/* Slider component with dynamic content */}
            <Slider {...settings}>
              {divs.map((div) => (
                <div key={div.id} className="p-3 review-div">
                  <img className="img-fluid" src={div.img}  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Reviews;
