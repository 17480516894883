import React from 'react';



export default function Fixed(){
    return(
        <>
        <section className='bg'>
        
        </section>
        </>
    )

}