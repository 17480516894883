import { features } from "process";

export const data = [
  {
    id: 1,
    title: "Hillcrest at Town Square Dubai",
    location: "Town Square Dubai",
    category: "off plan",
    prices: 600000,
    status: "OFF Plan",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
    ],
    image: [
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-11.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Hillcrest-at-Town-Square-Dubai-7-qu1rjiz2cn47ilvqc794x71rukhne0n3gqrnevbfno.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Hillcrest-at-Town-Square-Dubai-6-qu1rje9vegxrwk2k3n802q8gvn4tbj4fs3i80hieis.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-8.jpg",
    ],
    type: "Apartments",
    optionl: "ajman",
    propertytype: "apartment",
    stratingPrice: "AED 980,888",
    developer: "Nshama",
    unittypes: "1, 2 & 3 Bedroom Apartments, 3 Bedroom Duplexes",
    size: "730.65 to 2,171.19 Sq Ft",
    downpayment: "10%",
    paymentplan: "50/50",
    handover: "June 2027",
    overview:
      "Hillcrest at Town Square by Nshama is the newest residential community offering premium 1, 2, and 3-bedroom apartments, along with 3-bedroom duplexes designed to provide a luxurious living experience. This exclusive project is set within the thriving Town Square Dubai, known for its beautiful landscaped parks and vibrant community life. Every residence at Hillcrest is crafted with meticulous attention to detail, combining modern design elements with natural surroundings for an unparalleled living environment.",
    para1:
      "The project is perfectly located in Town Square Dubai, placing residents just steps away from Town Square Park and its array of outdoor activities like jogging and cycling tracks, ideal for those who appreciate fitness and nature. Hillcrest offers a modern architectural design that harmonizes with the surrounding greenery, featuring sleek lines, large windows, and interiors that maximize natural light and space. This urban oasis is designed to encourage an active lifestyle, with expansive green areas, outdoor facilities, and wellness-focused amenities.",
    para2:
      "Each apartment is finished with high-quality materials such as porcelain tiled floors, built-in wardrobes, and fully equipped kitchens, offering both style and functionality. Residents will enjoy stunning views of the surrounding green spaces and have access to a variety of community features like swimming pools, gyms, children’s play areas, and sports courts. The project also includes convenient access to retail outlets, cafes, and other essential services within walking distance.",
    para3:
      "With a flexible 50/50 payment plan and a 10% down payment, Hillcrest at Town Square presents an attractive investment opportunity in a thriving area with a promising future for capital appreciation.",
    unitoptions:
      " 1, 2, and 3-bedroom apartments, including spacious 3-bedroom duplexes to cater to different family sizes.",
    primelocation:
      "Situated in the heart of Town Square Dubai, offering a dynamic lifestyle with easy access to Town Square Park, retail outlets, cafes, and dining options.",
    moderndesign:
      "Contemporary architecture combined with expansive green spaces, encouraging a seamless blend of urban living and nature.",
    elegantinteriors:
      "High-end finishes such as porcelain tiled floors, built-in wardrobes, and fully-equipped kitchens, providing a blend of luxury and practicality.",
    outdoorliving:
      "Surrounded by kilometers of landscaped areas, jogging trails, and cycling paths, promoting a healthy and active lifestyle.",
    familyfriendlyamenities:
      "Access to children’s playgrounds, swimming pools, gyms, BBQ areas, and more, ensuring a vibrant and engaging environment for all residents.",
    sustainablelifestyle:
      "Thoughtfully designed green spaces and energy-efficient building materials contribute to a sustainable way of living.",
    amenities: [
      "24×7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surroundings",
      "Gymnasium",
      "Health Care Centre",
      "Kids Play Area",
      "Multi-Purpose Lounge",
      "Parks and Leisure Areas",
      "Restaurants",
      "Retail Outlets",
      "Sports Court",
      "Supermarket",
      "Swimming Pool",
      "Tennis Courts",
    ],
    paymentPlan: ["10%", "40% (1st to 4th Installment)", "50%"],
    propertystatus: "for rent",
    bed: "1+",
    baths: "1+",
  },
  {
    id: 2,
    title: "Hillcrest at Town Square Dubai",
    location: "Town Square Dubai",
    category: "on plan",
    prices: 8000000,
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
    ],
    status: "OFF Plan",
    optionl: "ai barsha",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-11.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Hillcrest-at-Town-Square-Dubai-7-qu1rjiz2cn47ilvqc794x71rukhne0n3gqrnevbfno.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Hillcrest-at-Town-Square-Dubai-6-qu1rje9vegxrwk2k3n802q8gvn4tbj4fs3i80hieis.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-8.jpg",
    ],
    type: "Apartments",
    propertytype: "apartment",
    stratingPrice: "AED 980,888",
    developer: "Nshama",
    unittypes: "1, 2 & 3 Bedroom Apartments, 3 Bedroom Duplexes",
    size: "730.65 to 2,171.19 Sq Ft",
    downpayment: "10%",
    paymentplan: "50/50",
    handover: "June 2027",
    overview:
      "Hillcrest at Town Square by Nshama is the newest residential community offering premium 1, 2, and 3-bedroom apartments, along with 3-bedroom duplexes designed to provide a luxurious living experience. This exclusive project is set within the thriving Town Square Dubai, known for its beautiful landscaped parks and vibrant community life. Every residence at Hillcrest is crafted with meticulous attention to detail, combining modern design elements with natural surroundings for an unparalleled living environment.",
    para1:
      "The project is perfectly located in Town Square Dubai, placing residents just steps away from Town Square Park and its array of outdoor activities like jogging and cycling tracks, ideal for those who appreciate fitness and nature. Hillcrest offers a modern architectural design that harmonizes with the surrounding greenery, featuring sleek lines, large windows, and interiors that maximize natural light and space. This urban oasis is designed to encourage an active lifestyle, with expansive green areas, outdoor facilities, and wellness-focused amenities.",
    para2:
      "Each apartment is finished with high-quality materials such as porcelain tiled floors, built-in wardrobes, and fully equipped kitchens, offering both style and functionality. Residents will enjoy stunning views of the surrounding green spaces and have access to a variety of community features like swimming pools, gyms, children’s play areas, and sports courts. The project also includes convenient access to retail outlets, cafes, and other essential services within walking distance.",
    para3:
      "With a flexible 50/50 payment plan and a 10% down payment, Hillcrest at Town Square presents an attractive investment opportunity in a thriving area with a promising future for capital appreciation.",
    unitoptions:
      " 1, 2, and 3-bedroom apartments, including spacious 3-bedroom duplexes to cater to different family sizes.",
    primelocation:
      "Situated in the heart of Town Square Dubai, offering a dynamic lifestyle with easy access to Town Square Park, retail outlets, cafes, and dining options.",
    moderndesign:
      "Contemporary architecture combined with expansive green spaces, encouraging a seamless blend of urban living and nature.",
    elegantinteriors:
      "High-end finishes such as porcelain tiled floors, built-in wardrobes, and fully-equipped kitchens, providing a blend of luxury and practicality.",
    outdoorliving:
      "Surrounded by kilometers of landscaped areas, jogging trails, and cycling paths, promoting a healthy and active lifestyle.",
    familyfriendlyamenities:
      "Access to children’s playgrounds, swimming pools, gyms, BBQ areas, and more, ensuring a vibrant and engaging environment for all residents.",
    sustainablelifestyle:
      "Thoughtfully designed green spaces and energy-efficient building materials contribute to a sustainable way of living.",
    amenities: [
      "24×7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surroundings",
      "Gymnasium",
      "Health Care Centre",
      "Kids Play Area",
      "Multi-Purpose Lounge",
      "Parks and Leisure Areas",
      "Restaurants",
      "Retail Outlets",
      "Sports Court",
      "Supermarket",
      "Swimming Pool",
      "Tennis Courts",
    ],
    paymentPlan: ["10%", "40% (1st to 4th Installment)", "50%"],
    propertystatus: "for sell",
    bed: "2+",
    baths: "1+",
  },
  {
    id: 3,
    title: "Hillcrest at Town Square Dubai",
    location: "Town Square Dubai",
    category: "ready to move",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
    ],
    prices: 9000000,
    status: "OFF Plan",
    optionl: "dubai hillestate",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-11.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Hillcrest-at-Town-Square-Dubai-7-qu1rjiz2cn47ilvqc794x71rukhne0n3gqrnevbfno.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Hillcrest-at-Town-Square-Dubai-6-qu1rje9vegxrwk2k3n802q8gvn4tbj4fs3i80hieis.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-8.jpg",
    ],
    type: "Apartments",
    propertytype: "apartment",
    stratingPrice: "AED 980,888",
    developer: "Nshama",
    unittypes: "1, 2 & 3 Bedroom Apartments, 3 Bedroom Duplexes",
    size: "730.65 to 2,171.19 Sq Ft",
    downpayment: "10%",
    paymentplan: "50/50",
    handover: "June 2027",
    overview:
      "Hillcrest at Town Square by Nshama is the newest residential community offering premium 1, 2, and 3-bedroom apartments, along with 3-bedroom duplexes designed to provide a luxurious living experience. This exclusive project is set within the thriving Town Square Dubai, known for its beautiful landscaped parks and vibrant community life. Every residence at Hillcrest is crafted with meticulous attention to detail, combining modern design elements with natural surroundings for an unparalleled living environment.",
    para1:
      "The project is perfectly located in Town Square Dubai, placing residents just steps away from Town Square Park and its array of outdoor activities like jogging and cycling tracks, ideal for those who appreciate fitness and nature. Hillcrest offers a modern architectural design that harmonizes with the surrounding greenery, featuring sleek lines, large windows, and interiors that maximize natural light and space. This urban oasis is designed to encourage an active lifestyle, with expansive green areas, outdoor facilities, and wellness-focused amenities.",
    para2:
      "Each apartment is finished with high-quality materials such as porcelain tiled floors, built-in wardrobes, and fully equipped kitchens, offering both style and functionality. Residents will enjoy stunning views of the surrounding green spaces and have access to a variety of community features like swimming pools, gyms, children’s play areas, and sports courts. The project also includes convenient access to retail outlets, cafes, and other essential services within walking distance.",
    para3:
      "With a flexible 50/50 payment plan and a 10% down payment, Hillcrest at Town Square presents an attractive investment opportunity in a thriving area with a promising future for capital appreciation.",
    unitoptions:
      " 1, 2, and 3-bedroom apartments, including spacious 3-bedroom duplexes to cater to different family sizes.",
    primelocation:
      "Situated in the heart of Town Square Dubai, offering a dynamic lifestyle with easy access to Town Square Park, retail outlets, cafes, and dining options.",
    moderndesign:
      "Contemporary architecture combined with expansive green spaces, encouraging a seamless blend of urban living and nature.",
    elegantinteriors:
      "High-end finishes such as porcelain tiled floors, built-in wardrobes, and fully-equipped kitchens, providing a blend of luxury and practicality.",
    outdoorliving:
      "Surrounded by kilometers of landscaped areas, jogging trails, and cycling paths, promoting a healthy and active lifestyle.",
    familyfriendlyamenities:
      "Access to children's playgrounds, swimming pools, gyms, BBQ areas, and more, ensuring a vibrant and engaging environment for all residents.",
    sustainablelifestyle:
      "Thoughtfully designed green spaces and energy-efficient building materials contribute to a sustainable way of living.",
    amenities: [
      "24×7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surroundings",
      "Gymnasium",
      "Health Care Centre",
      "Kids Play Area",
      "Multi-Purpose Lounge",
      "Parks and Leisure Areas",
      "Restaurants",
      "Retail Outlets",
      "Sports Court",
      "Supermarket",
      "Swimming Pool",
      "Tennis Courts",
    ],
    paymentPlan: ["10%", "40% (1st to 4th Installment)", "50%"],
    propertystatus: "for buy",
    bed: "2+",
    baths: "2+",
  },
  {
    id: 4,
    title: "Oasiz by Danube Properties",
    location: "Dubai Silicon Oasis",
    category: "off plan",
    prices: 1200000,
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    status: "OFF Plan",
    optionl: "ras al khaimah",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-qu011r7m6arv9assxe4trd32maal5qhuxhczjepass.png",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-qu011r7m6arv9assxe4trd32maal5qhuxhczjepass.png",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-1-qu010w6ube64iv1ugcpjq4v739kn03s9d57y9ocq2s.png",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-1-qu010w6ube64iv1ugcpjq4v739kn03s9d57y9ocq2s.png",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-14-qu010plyzjx49lbeirv5qoiyxkh2i82508njwqmhac.png",
    ],
    type: "Apartments",
    propertytype: "apartment",
    stratingPrice: "AED 775,000",
    developer: "Danube Properties",
    unittypes: " Studio, 1, 2 & 3 Bedrooms",
    size: " Various Sizes",
    downpayment: "Call Us",
    paymentplan: "65/35, 1% Monthly",
    handover: "November 2027",
    overview:
      "Oasiz by Danube Properties is an exciting new development set to redefine luxury living in Dubai Silicon Oasis. This 38-floor high-rise is designed to offer a harmonious blend of sophistication, functionality, and innovation. The project features a range of apartment options including studio units, 1-bedroom, 2-bedroom, and 3-bedroom residences, all meticulously crafted with premium finishes and open layouts that enhance the sense of space and light.",
    para1:
      "Strategically located in Dubai Silicon Oasis, Oasiz benefits from excellent connectivity to key Dubai landmarks via major highways like Sheikh Mohammed Bin Zayed Road and Dubai-Al Ain Road. Residents will enjoy quick access to Downtown Dubai, Dubai Mall, and Dubai International Airport. The development is also situated close to thriving tech industries, top educational institutions, and a vibrant community, making it an ideal location for both professionals and families.",
    para2:
      "The amenities at Oasiz are designed to provide a luxurious living experience. Residents can enjoy a serene swimming pool, a fully-equipped gym, lush landscaped gardens, and a dedicated children’s play area. The project also offers 24-hour security, ample parking, and concierge services to ensure convenience and peace of mind",
    para3:
      "The flexible payment plan allows a 10% down payment, with 40% payable during construction and the remaining 50% due upon handover. Oasiz represents a compelling investment opportunity with strong potential for capital growth in one of Dubai’s most dynamic communities.",
    unitoptions: "",
    primelocation: "",
    moderndesign: "",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    sustainablelifestyle: "",
    propertystatus: "for rent",
    apartmentoptions:
      " Studios, 1-, 2-, and 3-bedroom units with high-end finishes and open layouts.",
    locationk:
      "Central position in Dubai Silicon Oasis with excellent connectivity to major Dubai areas and transport hubs.",
    amenitiesk:
      "Includes a swimming pool, gym, landscaped gardens, children’s play area, and 24-hour security.",
    investmentpotential:
      "Attractive payment plan and high potential for property value growth.",
    communityfeatures:
      " Encompasses an environmentally responsible lifestyle with proximity to tech hubs, educational institutions, and recreational facilities.",
    amenities: [
      "Dining Outlets",
      "Gymnasium",
      "Health Care Centre",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Retail Outlets",
      "Supermarket",
      "Swimming Pool",
    ],
    paymentPlan: [],
    tillhandover: " 65%",
    posthandover: "35% (Within 35 Months)",
    bed: "1+",
    baths: "1+",
  },
  {
    id: 5,
    title: "Lavita at The Oasis by Emaar Properties",
    location: " The Oasis",
    category: "off plan",
    optionl: "ras al khaimah",
    prices: 1900000,
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    status: "OFF Plan",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-5-qtzrim8jo7maum863wh7a09nv6lz3vyyn17u5dultw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-5-qtzrim8jo7maum863wh7a09nv6lz3vyyn17u5dultw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-5-qtzrim8jo7maum863wh7a09nv6lz3vyyn17u5dultw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-5-qtzrim8jo7maum863wh7a09nv6lz3vyyn17u5dultw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-5-qtzrim8jo7maum863wh7a09nv6lz3vyyn17u5dultw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-5-qtzrim8jo7maum863wh7a09nv6lz3vyyn17u5dultw.jpg",
    ],
    type: "Apartments",
    propertytype: "apartment",
    stratingPrice: "AED 36,610,000",
    developer: "Emaar Properties",
    unittypes: "6 & 7 Bedrooms",
    size: "19,012 to 28,502 Sq Ft",
    downpayment: "20%",
    paymentplan: "80/20",
    handover: "December 2028",
    overview:
      "Lavita at The Oasis, an extraordinary development by Emaar Properties, brings a collection of 43 ultra-luxurious 6 and 7-bedroom mansions to The Oasis. Each mansion is meticulously crafted with attention to detail, ensuring that every residence is a masterpiece. The spacious layouts, premium finishes, and large windows that allow abundant natural light create an ambiance of elegance and warmth.",
    para1:
      "Situated in The Oasis, one of Dubai’s most coveted communities, Lavita offers seamless connectivity to Downtown Dubai, Dubai Marina, and both Dubai International Airport and Al Maktoum International Airport via Al Khail Road. Residents enjoy proximity to Dubai Hills Mall and Dubai Marina Mall, ensuring easy access to retail, dining, and entertainment options.",
    para2:
      "Lavita at The Oasis is set amidst lush landscapes and serene water features, providing an unparalleled living experience. The community boasts world-class amenities, including pristine beaches, jogging and cycling paths, outdoor gyms, and sports courts. The verdant parks and palm tree-lined streets create a tranquil environment, allowing residents to live in luxury while staying connected to nature.",
    para3: "",
    unitoptions: "",
    primelocation: "",
    moderndesign: "",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    sustainablelifestyle: "",
    apartmentoptions:
      "43 exclusive 6 & 7-bedroom mansions with spacious layouts and high-end finishes.",
    locationk:
      "CIdeally located in The Oasis, offering easy connectivity to key Dubai locations.",
    amenitiesk:
      "Premium amenities, including private beaches, jogging paths, sports courts, and outdoor gyms.",
    investmentpotential:
      "Attractive payment plan and high potential for property value growth.",
    communityfeatures:
      "Expansive windows, sleek interiors, and lush green surroundings create a bright and peaceful atmosphere.",
    amenities: [
      "24×7 Security",
      "Beach Access",
      "Cycling Trails",
      "Gymnasium",
      "Jogging Trails",
      "Kids Play Area",
      "Retail Outlets",
      "Running Track",
      "Swimming Pool",
    ],
    paymentPlan: [],
    tillhandover: " 65%",
    posthandover: "35% (Within 35 Months)",
    propertystatus: "for buy",
    bed: "1+",
    baths: "2+",
  },
  {
    id: 6,
    title: "Lavita at The Oasis by Emaar Properties",
    location: " The Oasis, Dubai",
    category: "on plan",
    prices: 1800000,
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    status: "OFF Plan",
    optionl: "ras al khaimah",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-by-Emaar-Properties-qtqzaflegoybnxe296xxzpnf4grwcru5wmguoix5nw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-by-Emaar-Properties-qtqzaflegoybnxe296xxzpnf4grwcru5wmguoix5nw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-by-Emaar-Properties-qtqzaflegoybnxe296xxzpnf4grwcru5wmguoix5nw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-by-Emaar-Properties-qtqzaflegoybnxe296xxzpnf4grwcru5wmguoix5nw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-by-Emaar-Properties-qtqzaflegoybnxe296xxzpnf4grwcru5wmguoix5nw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Lavita-at-The-Oasis-by-Emaar-Properties-qtqzaflegoybnxe296xxzpnf4grwcru5wmguoix5nw.jpg",
    ],
    type: "Villa",
    propertytype: "villa",
    stratingPrice: "Call Us",
    developer: "Emaar Properties",
    unittypes: "3 to 6 Bedroom Villas",
    size: "Various Sizes",
    downpayment: "20%",
    paymentplan: "Easy Payment Plan",
    handover: "Announcing Soon",
    overview: "",
    propertystatus: "for sell",
    para1:
      "Lavita at The Oasis is an exclusive villa community by Emaar Properties, nestled in one of Dubai’s most sought-after locations, The Oasis. This upcoming development features 43 beautifully crafted villas with spacious layouts ranging from 3 to 6 bedrooms, designed with impeccable attention to detail. The villas boast expansive spaces, premium finishes, and modern fixtures, ensuring luxury at every turn. Lavita is a masterpiece of architectural excellence, perfectly blending contemporary design with the serene beauty of nature.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation: "",
    moderndesign: "",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    sustainablelifestyle: "",
    apartmentoptions:
      "43 exclusive 6 & 7-bedroom mansions with spacious layouts and high-end finishes.",
    locationk:
      "CIdeally located in The Oasis, offering easy connectivity to key Dubai locations.",
    amenitiesk:
      "Premium amenities, including private beaches, jogging paths, sports courts, and outdoor gyms.",
    investmentpotential:
      "Attractive payment plan and high potential for property value growth.",
    communityfeatures:
      "Expansive windows, sleek interiors, and lush green surroundings create a bright and peaceful atmosphere.",
    amenities: [
      "Beach Access",
      "Cycling Trails",
      "Outdoor Gymnasium",
      "Jogging & Running Tracks",
      "Kids Play Area",
      "Retail Outlets",
      "Sports Courts",
      "Retail Outlets & Dining Options",
      "Lush Parks and Verdant Landscapes",
      "Swimming Pool",
    ],
    paymentPlan: [],
    tillhandover: " 65%",
    posthandover: "35% (Within 35 Months)",
    locationhigh: [
      "20 minutes to Downtown Dubai",
      "28 minutes to Dubai Marina",
      "Easy access to Dubai International Airport and Al Maktoum International Airport",
      "Close to Dubai Hills Mall and Dubai Marina Mall",
    ],
    baths: "1+",
    bed: "2+",
  },
  {
    id: 7,
    title: "Binghatti Ghost at Al Jaddaf",
    location: "Al Jaddaf, Dubai",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    category: "on plan",
    prices: 1200000,
    status: "OFF Plan",
    optionl: "dubai hillestate",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Binghatti-Ghost-at-Al-Jaddaf-qtpp57hvxiv0tmeun4ajax0858qom8w1ibo0zm1aek.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Binghatti-Ghost-at-Al-Jaddaf-qtpp57hvxiv0tmeun4ajax0858qom8w1ibo0zm1aek.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Binghatti-Ghost-at-Al-Jaddaf-qtpp57hvxiv0tmeun4ajax0858qom8w1ibo0zm1aek.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Binghatti-Ghost-at-Al-Jaddaf-qtpp57hvxiv0tmeun4ajax0858qom8w1ibo0zm1aek.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Binghatti-Ghost-at-Al-Jaddaf-qtpp57hvxiv0tmeun4ajax0858qom8w1ibo0zm1aek.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Binghatti-Ghost-at-Al-Jaddaf-qtpp57hvxiv0tmeun4ajax0858qom8w1ibo0zm1aek.jpg",
    ],
    type: "Apartment",
    propertytype: "apartment",
    stratingPrice: "AED 888,888",
    developer: "Binghatti Developers",
    unittypes: "Studio, 1, 2, and 3 Bedroom Apartments",
    size: "358 to 2,452 sq. ft.",
    downpayment: "20%",
    paymentplan: "Easy Payment Plan",
    handover: "July 2026",
    overview: "",
    propertystatus: "for rent",
    para1:
      "Lavita at The Oasis is an exclusive villa community by Emaar Properties, nestled in one of Dubai’s most sought-after locations, The Oasis. This upcoming development features 43 beautifully crafted villas with spacious layouts ranging from 3 to 6 bedrooms, designed with impeccable attention to detail. The villas boast expansive spaces, premium finishes, and modern fixtures, ensuring luxury at every turn. Lavita is a masterpiece of architectural excellence, perfectly blending contemporary design with the serene beauty of nature.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation: "",
    moderndesign: "",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    sustainablelifestyle: "",
    apartmentoptions:
      "43 exclusive 6 & 7-bedroom mansions with spacious layouts and high-end finishes.",
    locationk:
      "CIdeally located in The Oasis, offering easy connectivity to key Dubai locations.",
    amenitiesk:
      "Premium amenities, including private beaches, jogging paths, sports courts, and outdoor gyms.",
    investmentpotential:
      "Attractive payment plan and high potential for property value growth.",
    communityfeatures:
      "Expansive windows, sleek interiors, and lush green surroundings create a bright and peaceful atmosphere.",
    amenities: [
      "Beach Access",
      "Cycling Trails",
      "Outdoor Gymnasium",
      "Jogging & Running Tracks",
      "Kids Play Area",
      "Retail Outlets",
      "Sports Courts",
      "Retail Outlets & Dining Options",
      "Lush Parks and Verdant Landscapes",
      "Swimming Pool",
    ],
    paymentPlan: [],
    tillhandover: " 65%",
    posthandover: "35% (Within 35 Months)",
    locationhigh: [
      "20 minutes to Downtown Dubai",
      "28 minutes to Dubai Marina",
      "Easy access to Dubai International Airport and Al Maktoum International Airport",
      "Close to Dubai Hills Mall and Dubai Marina Mall",
    ],
    bed: "2+",
    baths: "1+",
  },
  {
    id: 8,
    title: "Damac Sun City at Dubailand",
    location: "Al Jaddaf, Dubai",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    category: "ready to move",
    prices: 12390000,
    optionl: "dubai hillestate",
    status: "OFF Plan",
    propertystatus: "for sell",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Damac-Sun-City-at-Dubailand-qtnx6hhn5slwft8tx968fi12a8keo68p8wmnfeazn0.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Damac-Sun-City-at-Dubailand-qtnx6hhn5slwft8tx968fi12a8keo68p8wmnfeazn0.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Damac-Sun-City-at-Dubailand-qtnx6hhn5slwft8tx968fi12a8keo68p8wmnfeazn0.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Damac-Sun-City-at-Dubailand-qtnx6hhn5slwft8tx968fi12a8keo68p8wmnfeazn0.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Damac-Sun-City-at-Dubailand-qtnx6hhn5slwft8tx968fi12a8keo68p8wmnfeazn0.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Damac-Sun-City-at-Dubailand-qtnx6hhn5slwft8tx968fi12a8keo68p8wmnfeazn0.jpg",
    ],
    type: "Apartment",
    propertytype: "apartment",
    stratingPrice: "AED 2.25M for 4 bedrooms, AED 3.01M for 5 bedrooms",
    developer: "Damac Properties",
    unittypes: "4 and 5 Bedroom Townhouses",
    size: "2,319 to 3,324 sq. ft",
    downpayment: "20%",
    paymentplan: "Easy Payment Plan",
    handover: "Anticipated completion by March 2028",
    average: "AED 2.72M for 4 bedrooms, AED 3.83M for 5 bedrooms",
    nounit: [
      "4 Bedroom Townhouses: 523 Units",
      "5 Bedroom Townhouses: 226 Units",
    ],
    overview: "",
    para1:
      "Lavita at The Oasis is an exclusive villa community by Emaar Properties, nestled in one of Dubai’s most sought-after locations, The Oasis. This upcoming development features 43 beautifully crafted villas with spacious layouts ranging from 3 to 6 bedrooms, designed with impeccable attention to detail. The villas boast expansive spaces, premium finishes, and modern fixtures, ensuring luxury at every turn. Lavita is a masterpiece of architectural excellence, perfectly blending contemporary design with the serene beauty of nature.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation: "",
    moderndesign: "",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    sustainablelifestyle: "",
    apartmentoptions:
      "43 exclusive 6 & 7-bedroom mansions with spacious layouts and high-end finishes.",
    locationk:
      "CIdeally located in The Oasis, offering easy connectivity to key Dubai locations.",
    amenitiesk:
      "Premium amenities, including private beaches, jogging paths, sports courts, and outdoor gyms.",
    investmentpotential:
      "Attractive payment plan and high potential for property value growth.",
    communityfeatures:
      "Expansive windows, sleek interiors, and lush green surroundings create a bright and peaceful atmosphere.",
    amenities: [
      "Beach Access",
      "Cycling Trails",
      "Fitness Centre & Gymnasium",
      "Infinity Pool",
      "Parks and Leisure Areas",
      "Restaurants and Dining Outlets",
      "Kids Play Area",
      "Marina & Yacht Club",
      "Supermarket and Retail Options",
      "Swimming Pool",
    ],
    paymentPlan: [],
    tillhandover: "",
    posthandover: "",
    locationhigh: [
      "20 minutes to Downtown Dubai",
      "25 minutes to Dubai International Airport",
      "8 minutes to Dubai Outlet Mall",
      "5 minutes to Dubai Rugby Sevens",
    ],
    payment: "75:25",
    bed: "2+",
    baths: "2+",
  },
  {
    id: 9,
    title: "The Rings by PMR",
    location: "Dubai Water Canal, Jumeirah",
    category: "off plan",
    prices: 23490000,
    propertystatus: "for rent",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    status: "OFF Plan",
    optionl: "dubai hillestate",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/The-Rings-by-PMR-19-qtw3i4d4j78fughrurycp88yge11416h0fkgm61u5o.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/The-Rings-by-PMR-18-qtw3hwubf5iv69sokioqwc3ls039au25vbqkccqf44.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/The-Rings-by-PMR-20-qtw3iaxw9o260f87a6s5fqjios5riam4x9iuji5iis.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/The-Rings-by-PMR-19-qtw3i4d4j78fughrurycp88yge11416h0fkgm61u5o.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/The-Rings-by-PMR-18-qtw3hwubf5iv69sokioqwc3ls039au25vbqkccqf44.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/The-Rings-by-PMR-20-qtw3iaxw9o260f87a6s5fqjios5riam4x9iuji5iis.jpg",
    ],
    type: "Sky Mansions & Sky Palaces",
    propertytype: "sky Palaces",
    stratingPrice: "AED 59 Million",
    developer: "PMR Property",
    unittypes: " 5, 6, and 7 Bedrooms",
    size: "9,500 to 19,800 sq. ft.",
    downpayment: "20%",
    paymentplan: "Easy Payment Plan",
    handover: "Q4 2025",
    average: "AED 2.72M for 4 bedrooms, AED 3.83M for 5 bedrooms",
    nounit: [
      "4 Bedroom Townhouses: 523 Units",
      "5 Bedroom Townhouses: 226 Units",
    ],
    overview:
      "The Rings by PMR is a premium waterfront development located along the Dubai Water Canal in Jumeirah. This iconic project, designed by the internationally acclaimed Foster + Partners, offers an exclusive collection of Sky Mansions and Sky Palaces. Each residence is meticulously crafted to deliver an unparalleled lifestyle experience, with panoramic views of Dubai’s most famous landmarks, including the Burj Khalifa and the Dubai skyline.",
    para1:
      "The luxurious development consists of two low-rise buildings with only six palatial residences in each. Offering 5, 6, and 7-bedroom units, these exclusive homes range in size from 9,500 to 19,800 sq. ft., providing spacious living for discerning buyers. The development comes with a variety of high-end amenities, including private garages, air-conditioned lifts, swimming pools, home cinemas, and private gyms.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation:
      " Minutes away from Downtown Dubai, Dubai Marina, Jumeirah Beach, and major roadways such as Sheikh Zayed Road.",
    moderndesign:
      "Designed by Foster + Partners, with art by Mattar Bin Lahej, known for his work at the Museum of the Future.",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    sustainablelifestyle: "",
    apartmentoptions:
      "Limited collection of 5, 6, and 7-bedroom Sky Mansions and Sky Palaces.",
    locationk: "",
    amenitiesk: "",
    investmentpotential: "",
    communityfeatures: "",
    exclusiveresidences: "",
    architecturalmasterpiece: "",
    amenities: [
      "Beach Access",
      "Private swimming pools and gardens",
      "Home cinema and gym",
      "Kids’ play area",
      "Private garages and lifts",
      "World-class spa and wellness center",
      "Exclusive a la carte services, including housekeeping and chauffeur services",
    ],
    paymentPlan: "",
    tillhandover: "",
    posthandover: "",
    baths: "1+",
    locationhigh: [
      "2 minutes to Business Bay Metro",
      "18 minutes to Dubai Marina",
      "10 minutes to Jumeirah Beach",
      "28 minutes to Dubai International Airport (DXB)",
    ],
    payment: "75:25",
    bed: "2+",
  },
  {
    id: 10,
    title: "Cove Edition by Imtiaz",
    location: "Dubailand",
    category: "off plan",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    propertystatus: "for sell",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Cove-Edition-by-Imtiaz-14-qtiqmo5xf35x5oc9kb1vk7ocytyn04x8kpkl87ws18.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Cove-Edition-by-Imtiaz-4-qtiql0xzv5hkqxq9nxfsg6knm93ofl3owhuzk6q0hw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Cove-Edition-by-Imtiaz-16-qtiqk33t14794z3f5it7yf428dqgqhdcrudia846pw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Cove-Edition-by-Imtiaz-14-qtiqmo5xf35x5oc9kb1vk7ocytyn04x8kpkl87ws18.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Cove-Edition-by-Imtiaz-4-qtiql0xzv5hkqxq9nxfsg6knm93ofl3owhuzk6q0hw.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Cove-Edition-by-Imtiaz-16-qtiqk33t14794z3f5it7yf428dqgqhdcrudia846pw.jpg",
    ],
    type: "Apartment",
    propertytype: "apartment",
    optionl: "ajman",
    stratingPrice: "600K",
    developer: "Imtiaz Developments",
    unittypes: "Studio, 1 & 2 Bedroom",
    size: " Various Sizes",
    downpayment: "Call Us",
    paymentplan: "Easy Payment Plan",
    handover: "June 2026",
    average: "",
    nounit: [],
    overview:
      "Cove Edition by Imtiaz Developments is a new landmark in Dubailand, offering a premium range of fully furnished studios, 1-bedroom, and 2-bedroom apartments. This 2B+G+13 structure is designed for those who desire a sophisticated lifestyle, combining luxury and modern living. With world-class amenities and exclusive features, Cove Edition sets a new standard for upscale living in Dubai.",
    para1:
      "Nestled in the heart of Dubailand, Cove Edition offers seamless access to major landmarks such as Downtown Dubai, Dubai International Airport, and Dubai Mall, all within a 20-minute drive. Residents also enjoy proximity to Dubai Silicon Oasis, Global Village, and IMG World of Adventure, making it an ideal choice for a connected lifestyle.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation:
      " Minutes away from Downtown Dubai, Dubai Marina, Jumeirah Beach, and major roadways such as Sheikh Zayed Road.",
    moderndesign:
      "Designed by Foster + Partners, with art by Mattar Bin Lahej, known for his work at the Museum of the Future.",
    elegantinteriors: "",
    outdoorliving: "",
    familyfriendlyamenities: "",
    baths: "2+",
    sustainablelifestyle: "",
    apartmentoptions:
      "Limited collection of 5, 6, and 7-bedroom Sky Mansions and Sky Palaces.",
    locationk:
      "2 minutes to Business Bay Metro18 minutes to Dubai Marina10 minutes to Jumeirah Beach28 minutes to Dubai International Airport (DXB)",
    amenitiesk: "",
    investmentpotential: "",
    communityfeatures: "",
    exclusiveresidences: "",
    architecturalmasterpiece: "",
    amenities: [
      "Beach Access",
      "Private swimming pools and gardens",
      "Home cinema and gym",
      "Kids’ play area",
      "Private garages and lifts",
      "World-class spa and wellness center",
      "Exclusive a la carte services, including housekeeping and chauffeur services",
    ],
    paymentPlan: "",
    tillhandover: "",
    posthandover: "",
    locationhigh: [
      "2 minutes to Business Bay Metro",
      "18 minutes to Dubai Marina",
      "10 minutes to Jumeirah Beach",
      "28 minutes to Dubai International Airport (DXB)",
    ],
    payment: "75:25",
    prices: 9800000,
    bed: "2+",
    status: "OFF Plan",
  },
  {
    id: 11,
    title: "BAYZ 102 at Business Bay",
    location: "Business Bay, Dubai",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    category: "off plan",
    propertystatus: "for buy",
    optionl: "ajman",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/2024/08/BAYZ-102-by-Danube-1-570x370.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/08/BAYZ-102-by-Danube-1-570x370.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/08/BAYZ-102-by-Danube-1-570x370.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/08/BAYZ-102-by-Danube-1-570x370.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/08/BAYZ-102-by-Danube-1-570x370.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/08/BAYZ-102-by-Danube-1-570x370.jpg",
    ],
    type: "Penthouse, Apartment, Presidential Suite",
    stratingPrice: "AED 1,350,000",
    developer: "Danube Properties",
    unittypes:
      "Studios, 1, 2, 3 & 4 Bedroom Apartments, 2-Bedroom Presidential Suites",
    size: "Various Sizes Available",
    downpayment: "10%",
    paymentplan: "70/30 (1% Monthly)",
    handover: "December 2028",
    average: "",
    nounit: [],
    overview:
      "BAYZ 102 by Danube Properties is an iconic new development in Business Bay, featuring luxury studios, 1, 2, 3, and 4-bedroom apartments along with exclusive presidential suites. This high-rise tower boasts stunning panoramic views of the Burj Khalifa, Dubai Canal, and the vibrant city skyline, blending elegance and modernity in one of Dubai’s most sought-after locations.",
    para1:
      "Positioned just a 2-minute walk from the Business Bay Metro Station and with direct access to Sheikh Zayed Road, BAYZ 102 offers unparalleled convenience. Residents are minutes away from Downtown Dubai, The Dubai Mall, and upscale retail, dining, and entertainment options. The sleek architectural design reflects Dubai’s modern luxury, with large windows and glass facades allowing ample natural light and breathtaking views.",
    para2:
      "BAYZ 102 redefines urban living by offering state-of-the-art amenities like infinity pools, landscaped gardens, fitness centers, and retail outlets all within the development. It’s more than just a home—it’s a lifestyle of comfort, elegance, and accessibility.",
    para3: "",
    unitoptions:
      "Studios to 4-bedroom apartments and presidential suites, designed with modern interiors and floor-to-ceiling windows.",
    primelocation:
      "Prime location in Business Bay, with easy access to Downtown Dubai and Sheikh Zayed Road.",
    moderndesign:
      "Iconic views of Burj Khalifa, Dubai Canal, and the Arabian Gulf Coastline.",
    elegantinteriors:
      "Contemporary high-rise design with a shimmering glass façade.",
    outdoorliving:
      "Premium amenities including swimming pools, gym, yoga zone, kids play area, and landscaped gardens.",
    familyfriendlyamenities: "",
    sustainablelifestyle:
      "On-site dining options, retail outlets, and cafes for a complete lifestyle experience.",
    apartmentoptions: "",
    locationk: "",
    amenitiesk: "",
    investmentpotential: "",
    communityfeatures: "",
    exclusiveresidences: "",
    architecturalmasterpiece: "",
    amenities: [
      "24/7 Security",
      "Infinity Pool",
      "Kids Play Area",
      "Gymnasium",
      "Restaurants & Cafes",
      "Retail Outlets",
      "Tennis & Basketball Courts",
      "Marina & Yacht Club",
      "Spa & Sauna",
      "Green Surroundings",
      "BBQ Areas",
      "Jogging Trails",
    ],
    baths: "1+",
    paymentPlan: "10% on booking",
    tillhandover: "1%",
    posthandover: "30% within 30 months",
    locationhigh: [
      "Business Bay Metro Station: 2 minutes",
      "Dubai Mall: 5 minutes",
      "Dubai International Airport: 20 minutes",
      "Jumeirah Beach: 10 minutes",
      "Burj Al Arab: 15 minutes",
      "Dubai Marina: 25 minutes",
    ],
    payment: "",
    prices: 1900000,
    bed: "3+",
    status: "OFF Plan",
    propertytype: "villa",
  },
  {
    id: 12,
    title: "Legado at JVC by Prescott in Dubai",
    location: "Jumeirah Village Circle, Dubai",
    category: "ready to move",
    optionl: "dubai",
    propertystatus: "for sell",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-15-qu8hl5fvh6u33luuedp62sedsl446eey990t8j60vg.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-15-qu8hl5frvtet0autw7oktucpva5a2s4ht6essxjgg4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-2-qu8hlhnocnvj78d2wuyq899plah1uuh06uw41j1c78.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-1-qu8hlb2t0tmixymmza4c8sxhfldhcyqvtybpolb3es.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-1-qu8hlb2t0tmixymmza4c8sxhfldhcyqvtybpolb3es.jpg",
    ],
    type: "Apartments & Sky Villas",
    stratingPrice: "AED 650,000",
    developer: "Danube Properties",
    unittypes: "Studio: 358 Sq Ft",
    size: "Various Sizes Available,1, 2 & 3 Bedroom Apartments: 575 to 1,554 Sq Ft,Sky Villas (1, 2, 3 & 4 Bedroom): 2,908 to 4,128 Sq Ft",
    downpayment: "",
    paymentplan: "20% Down Payment, 60/40 Post Handover Plan",
    handover: "Q4 - 2027",
    average: "",
    nounit: [],
    overview:
      "Legado at JVC is a luxurious residential project by Prescott Real Estate Development. The 34-storey tower offers a range of well-designed studios, 1, 2, and 3-bedroom apartments, along with exclusive 2, 3, and 4-bedroom sky villas. The project combines modern architecture with practical living spaces, emphasizing luxury and convenience in the heart of Jumeirah Village Circle (JVC).",
    para1:
      "With its sleek glass façade and premium finishes, Legado creates a sophisticated living experience, offering panoramic views of the community and the city skyline. Residents enjoy spacious layouts, smart home technology, and a wide array of resort-style amenities spread across multiple floors.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation:
      "Situated in Jumeirah Village Circle, offering seamless access to major roads like Sheikh Mohammed Bin Zayed Road and Al Khail Road.",
    moderndesign:
      "A 34-storey modern masterpiece with reflective glass and metal façade.",
    elegantinteriors:
      "Every residence features modern finishes and smart home technology.",
    outdoorliving:
      "Private terraces, pools, and breathtaking views for ultimate luxury.",
    familyfriendlyamenities: "",
    sustainablelifestyle:
      "Infinity pool, sunbathing deck, fitness studio, coffee bar, and more.",
    apartmentoptions: "",
    locationk: "",
    amenitiesk: "",
    investmentpotential: "",
    communityfeatures: "",
    exclusiveresidences: "",
    architecturalmasterpiece: "",
    amenities: [
      "24/7 Security",
      "Infinity Pool",
      "Kids Play Area",
      "Gymnasium",
      "Restaurants & Cafes",
      "Retail Outlets",
      "Tennis & Basketball Courts",
      "Marina & Yacht Club",
      "Spa & Sauna",
      "Green Surroundings",
      "BBQ Areas",
      "Jogging Trails",
    ],
    paymentPlan: "10% on booking",
    bed: "2+",
    tillhandover: "1%",
    posthandover: "30% within 30 months",
    locationhigh: [
      "Business Bay Metro Station: 2 minutes",
      "Dubai Mall: 5 minutes",
      "Dubai International Airport: 20 minutes",
      "Jumeirah Beach: 10 minutes",
      "Burj Al Arab: 15 minutes",
      "Dubai Marina: 25 minutes",
    ],
    payment: "",
    baths: "2+",
    prices: 12345000,
    status: "OFF Plan",
    propertytype: "bungalow",
  },
  {
    id: 13,
    title: "Legado at JVC by Prescott in Dubai",
    location: "Jumeirah Village Circle, Dubai",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    category: "ready to move",
    propertystatus: "for rent",
    optionl: "dubai",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-15-qu8hl5fvh6u33luuedp62sedsl446eey990t8j60vg.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-15-qu8hl5frvtet0autw7oktucpva5a2s4ht6essxjgg4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-2-qu8hlhnocnvj78d2wuyq899plah1uuh06uw41j1c78.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-1-qu8hlb2t0tmixymmza4c8sxhfldhcyqvtybpolb3es.jpg",
      "https://houseandhedges.ae/wp-content/uploads/elementor/thumbs/Legado-at-JVC-1-qu8hlb2t0tmixymmza4c8sxhfldhcyqvtybpolb3es.jpg",
    ],
    type: "Apartments & Sky Villas",
    stratingPrice: "AED 650,000",
    developer: "Danube Properties",
    unittypes: "Studio: 358 Sq Ft",
    size: "Various Sizes Available,1, 2 & 3 Bedroom Apartments: 575 to 1,554 Sq Ft,Sky Villas (1, 2, 3 & 4 Bedroom): 2,908 to 4,128 Sq Ft",
    downpayment: "",
    paymentplan: "20% Down Payment, 60/40 Post Handover Plan",
    handover: "Q4 - 2027",
    average: "",
    nounit: [],
    overview:
      "Legado at JVC is a luxurious residential project by Prescott Real Estate Development. The 34-storey tower offers a range of well-designed studios, 1, 2, and 3-bedroom apartments, along with exclusive 2, 3, and 4-bedroom sky villas. The project combines modern architecture with practical living spaces, emphasizing luxury and convenience in the heart of Jumeirah Village Circle (JVC).",
    para1:
      "With its sleek glass façade and premium finishes, Legado creates a sophisticated living experience, offering panoramic views of the community and the city skyline. Residents enjoy spacious layouts, smart home technology, and a wide array of resort-style amenities spread across multiple floors.",
    para2: "",
    para3: "",
    unitoptions: "",
    primelocation:
      "Situated in Jumeirah Village Circle, offering seamless access to major roads like Sheikh Mohammed Bin Zayed Road and Al Khail Road.",
    moderndesign:
      "A 34-storey modern masterpiece with reflective glass and metal façade.",
    elegantinteriors:
      "Every residence features modern finishes and smart home technology.",
    outdoorliving:
      "Private terraces, pools, and breathtaking views for ultimate luxury.",
    familyfriendlyamenities: "",
    sustainablelifestyle:
      "Infinity pool, sunbathing deck, fitness studio, coffee bar, and more.",
    apartmentoptions: "",
    bed: "1+",
    locationk: "",
    amenitiesk: "",
    investmentpotential: "",
    communityfeatures: "",
    exclusiveresidences: "",
    architecturalmasterpiece: "",
    amenities: [
      "24/7 Security",
      "Infinity Pool",
      "Kids Play Area",
      "Gymnasium",
      "Restaurants & Cafes",
      "Retail Outlets",
      "Tennis & Basketball Courts",
      "Marina & Yacht Club",
      "Spa & Sauna",
      "Green Surroundings",
      "BBQ Areas",
      "Jogging Trails",
    ],
    paymentPlan: "10% on booking",
    tillhandover: "1%",
    posthandover: "30% within 30 months",
    locationhigh: [
      "Business Bay Metro Station: 2 minutes",
      "Dubai Mall: 5 minutes",
      "Dubai International Airport: 20 minutes",
      "Jumeirah Beach: 10 minutes",
      "Burj Al Arab: 15 minutes",
      "Dubai Marina: 25 minutes",
    ],
    payment: "",
    prices: 3459000,
    status: "OFF Plan",
    baths: "2+",
    propertytype: "studio",
  },
  {
    id: 14,
    title: "Hillcrest at Town Square Dubai",
    features: [
      "air conditioning",
      "barbeque",
      "children play area",
      "gym",
      "near hospital",
      "near mall",
      "swimming pool",
      "tv cable",
      "wifi",
    ],
    location: "Town Square Dubai",
    propertystatus: "for sell",
    propertytype: "apartments",
    optionl: "dubai",
    category: "on plan",
    baths: "1+",
    prices: 8903200,
    bed: "2+",
    status: "on plan",
    image: [
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-11.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-11.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-4.jpg",
    ],
    type: "Apartments",
    stratingPrice: "AED 980,888",
    developer: "Nshama",
    unittypes: "1, 2 & 3 Bedroom Apartments, 3 Bedroom Duplexes",
    size: "730.65 to 2,171.19 Sq Ft",
    downpayment: "10%",
    paymentplan: "50/50",
    handover: "June 2027",
    overview:
      "Hillcrest at Town Square by Nshama is the newest residential community offering premium 1, 2, and 3-bedroom apartments, along with 3-bedroom duplexes designed to provide a luxurious living experience. This exclusive project is set within the thriving Town Square Dubai, known for its beautiful landscaped parks and vibrant community life. Every residence at Hillcrest is crafted with meticulous attention to detail, combining modern design elements with natural surroundings for an unparalleled living environment.",
    para1:
      "The project is perfectly located in Town Square Dubai, placing residents just steps away from Town Square Park and its array of outdoor activities like jogging and cycling tracks, ideal for those who appreciate fitness and nature. Hillcrest offers a modern architectural design that harmonizes with the surrounding greenery, featuring sleek lines, large windows, and interiors that maximize natural light and space. This urban oasis is designed to encourage an active lifestyle, with expansive green areas, outdoor facilities, and wellness-focused amenities.",
    para2:
      "Each apartment is finished with high-quality materials such as porcelain tiled floors, built-in wardrobes, and fully equipped kitchens, offering both style and functionality. Residents will enjoy stunning views of the surrounding green spaces and have access to a variety of community features like swimming pools, gyms, children’s play areas, and sports courts. The project also includes convenient access to retail outlets, cafes, and other essential services within walking distance.",
    para3:
      "With a flexible 50/50 payment plan and a 10% down payment, Hillcrest at Town Square presents an attractive investment opportunity in a thriving area with a promising future for capital appreciation.",
    unitoptions:
      " 1, 2, and 3-bedroom apartments, including spacious 3-bedroom duplexes to cater to different family sizes.",
    primelocation:
      "Situated in the heart of Town Square Dubai, offering a dynamic lifestyle with easy access to Town Square Park, retail outlets, cafes, and dining options.",
    moderndesign:
      "Contemporary architecture combined with expansive green spaces, encouraging a seamless blend of urban living and nature.",
    elegantinteriors:
      "High-end finishes such as porcelain tiled floors, built-in wardrobes, and fully-equipped kitchens, providing a blend of luxury and practicality.",
    outdoorliving:
      "Surrounded by kilometers of landscaped areas, jogging trails, and cycling paths, promoting a healthy and active lifestyle.",
    familyfriendlyamenities:
      "Access to children’s playgrounds, swimming pools, gyms, BBQ areas, and more, ensuring a vibrant and engaging environment for all residents.",
    sustainablelifestyle:
      "Thoughtfully designed green spaces and energy-efficient building materials contribute to a sustainable way of living.",
    amenities: [
      "24×7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surroundings",
      "Gymnasium",
      "Health Care Centre",
      "Kids Play Area",
      "Multi-Purpose Lounge",
      "Parks and Leisure Areas",
      "Restaurants",
      "Retail Outlets",
      "Sports Court",
      "Supermarket",
      "Swimming Pool",
      "Tennis Courts",
    ],
    paymentPlan: ["10%", "40% (1st to 4th Installment)", "50%"],
  },
];
