import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"; // Import icons

// Define the Deal interface
interface Deal {
  id: number;
  imageUrl: string;
}

// Sample data with multiple images
const deals: Deal[] = [
  {
    id: 1,
    imageUrl: "https://houseandhedges.ae/wp-content/uploads/2023/04/octa.jpg",
  },
  {
    id: 2,
    imageUrl: "https://houseandhedges.ae/wp-content/uploads/2023/04/Meeras.png",
  },
  {
    id: 3,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/ORO-24-1.png",
  },
  {
    id: 4,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/VINCITORE.png",
  },
  {
    id: 5,
    imageUrl: "https://houseandhedges.ae/wp-content/uploads/2023/04/mag.png",
  },
  {
    id: 6,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/o-ptnr5.jpg",
  },
  {
    id: 7,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/o-ptnr3.jpg",
  },
  {
    id: 8,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/o-ptnr4.jpg",
  },
  {
    id: 9,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/Azizi-Developments-1.jpg",
  },
  {
    id: 10,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/MARQUISE-SQUARE.png",
  },
  {
    id: 11,
    imageUrl: "https://houseandhedges.ae/wp-content/uploads/2023/04/Omnit.jpg",
  },
  {
    id: 12,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/BEVERLY.webp",
  },
  {
    id: 13,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/PALM-HOLDING-SERENIA.png",
  },
  {
    id: 14,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/Q-GARDENS.png",
  },
  {
    id: 15,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/REPORTAGE-PROPERTIES.png",
  },
  {
    id: 16,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/SEVEN-TIDES.png",
  },
  {
    id: 17,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/the-dev-group.png",
  },
  {
    id: 18,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/SOUTH-BAY.png",
  },
  {
    id: 19,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/THE-HEART-OF-EUROPE-1.png",
  },
  {
    id: 20,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/liv-marina.jpg",
  },
  {
    id: 21,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/HABTOOR-CITY-1.png",
  },
  {
    id: 22,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/ELLINGTON-removebg-preview.png",
  },
  {
    id: 23,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/WASL-removebg-preview.png",
  },

  {
    id: 24,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/GREEN_GROUP-removebg-preview.png",
  },
  {
    id: 25,
    imageUrl:
      "https://houseandhedges.ae/wp-content/uploads/2023/04/12793fbda9ffaa8e3793e38219d6cd59-removebg-preview.png",
  },
];

export default function Ourpartner() {
  // State to track the current starting index
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(5); // Number of images to show at once

  // Function to adjust the number of visible items based on screen width
  const updateVisibleItems = () => {
    if (window.innerWidth <= 578) {
      setVisibleItems(1); // Mobile view (2 images)
    } else if (window.innerWidth <= 950) {
      setVisibleItems(3); // Tablet view (4 images)
    } else {
      setVisibleItems(5); // Laptop and larger (5 images)
    }
  };

  useEffect(() => {
    // Set the correct number of visible items on load and resize
    updateVisibleItems();
    window.addEventListener("resize", updateVisibleItems);
    return () => window.removeEventListener("resize", updateVisibleItems);
  }, []);

  // Function to handle the previous 5 partners click
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? deals.length - visibleItems : prevIndex - visibleItems
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2000); // Change images every 3 seconds

    // Clear the interval on unmount
    return () => clearInterval(interval);
  }, [currentIndex, visibleItems]);

  // Function to handle the next 5 partners click
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleItems >= deals.length ? 0 : prevIndex + visibleItems
    );
  };

  return (
    <div>
      <section className="gaps pb-4 section-1">
        <Container>
          <div>
            <div>
              <h2 className="txt-2">OUR PARTNERS</h2>
              <p className="txt-2">
                At Dimoora Real Estate, we specialize in managing premium
                properties across the UAE, turning homeownership into a reality.
                Whether you're looking to buy, sell, rent, or explore in-house
                mortgage options, we're here to guide you every step of the way.
                Let us help you achieve your dream of owning property in Dubai.
              </p>
            </div>

            <div className="d-flex align-items-center">
              {/* Previous icon */}
              <div>
                <LeftOutlined onClick={handlePrevious} className="less-icon" />
              </div>

              {/* Display the number of images according to screen size */}
              <div
                className="d-flex flex-wrap justify-content-evenly pt-3"
                style={{ flexGrow: 1 }}
              >
                {deals
                  .slice(currentIndex, currentIndex + visibleItems)
                  .map((deal) => (
                    <Card
                      key={deal.id}
                      hoverable
                      className="ourpartner-card"
                      cover={
                        <img
                          src={deal.imageUrl}
                          className="img-fluid our-img-part"
                          alt={`Partner ${deal.id}`}
                        />
                      }
                    ></Card>
                  ))}
              </div>

              {/* Next icon */}
              <RightOutlined onClick={handleNext} className="greater-icon" />
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
