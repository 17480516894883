import React, { useState } from "react";
import NavComponent from "../HOME/Navbar";
import Footer from "./Footer";
import { Button, Container } from "react-bootstrap";
import { Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";

export default function Careers() {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <>
      <NavComponent />

      <section className="carrers-container">
        <Container>
          <div>
            <div>
              <h2 className="txt-career-h">Career at Dimoora Real Estate</h2>
              <p className="txt-career-p">
                Embrace the culture of Dimoora Real Estate, where innovation,
                inclusion, and commitment are highly valued and actively
                recognized. Join our team and experience a workplace where every
                individual is respected and appreciated. We foster an
                environment that encourages creativity, embraces diversity, and
                rewards the dedication and hard work of our team members.
                Together, let's contribute to our shared success and make a
                difference in the real estate industry.
              </p>
            </div>

            <div>
              <Container>
                <div className="c-box-c">
                  <div className="c-box-combine">
                    <div className="c-box-1">
                      <div>
                        <label className="label-carrers">Name *</label>
                        <br />
                        <Input placeholder="First name" className="careers" />
                        <br />
                        <Input placeholder="Last name" className="careers" />
                        <br />
                        <label className="label-carrers">Father Name *</label>
                        <br />
                        <Input placeholder="first name" className="careers" />
                        <br />
                        <Input placeholder="Last name" className="careers" />
                        <br />
                        <label className="label-carrers">Contact no*</label>
                        <br />
                        <Input placeholder="Contact no" className="careers" />
                      </div>
                    </div>
                    <div className="c-box-2">
                      <div>
                        <label className="label-carrers">
                          Applied Position *
                        </label>
                        <br />

                        <Input placeholder="" className="careers" />
                        <br />
                        <label className="label-carrers">Qualification *</label>
                        <br />
                        <Input placeholder="" className="careers" />
                        <br />
                        <label className="label-carrers">Email*</label>
                        <br />
                        <Input
                          placeholder="Sample@gmail.com"
                          className="careers"
                        />
                        <br />
                        <label className="label-carrers">Addrees*</label>
                        <br />
                        <Input placeholder="" className="careers" />
                        <label className="label-carrers">Upload CV *</label>
                        <br/>
                        <Upload {...props}>
                          <Button className="file">
                            <UploadOutlined /> Select File
                          </Button>
                        </Upload>
                      </div>
                    </div>
                  </div>
                  <div className="careers-btn">
                    <Button className="btn-carrer">Sumbit</Button>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}
