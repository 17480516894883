import React from "react";
import NavComponent from "../HOME/Navbar";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

export default function Privacypolicy() {
  return (
    <>
      <NavComponent />
      <section className="carrers-container">
        <Container>
          <div>
            <p className="private-txt-h">Home / Privacy Policy</p>

            <p className="private-txt-p">
              At Dimoora Real Estate, we are committed to protecting the privacy
              and security of our clients' personal information. We value the
              trust that our clients place in us, and we strive to maintain the
              highest standards of confidentiality and data protection.
            </p>
            <h6 className="private-txt-h">Collection of Personal Information:</h6>

            <p className="private-txt-p">
              We collect personal information from our clients when they use our
              services, such as when they register on our website, contact us
              for inquiries, or sign up for our newsletter. The information we
              collect may include name, address, phone number, email address,
              and other relevant information necessary to provide our services.
            </p>

            <h6 className="private-txt-h">Use of Personal Information:</h6>

            <p className="private-txt-p">
              We use the personal information collected from our clients to
              provide our services, to communicate with them about their
              property needs, and to keep them informed of new listings and
              promotions. We may also use their personal information for
              administrative purposes, such as managing their account and
              billing.
            </p>

            <h6 className="private-txt-h">Sharing of Personal Information:</h6>
            <p className="private-txt-p">
              We do not share our clients' personal information with any third
              party unless it is necessary to provide our services or as
              required by law. We may share information with our partners, such
              as mortgage brokers or other real estate professionals, to help
              facilitate a transaction. However, we will only share the minimum
              amount of information necessary to accomplish the intended
              purpose.
            </p>
            <h6 className="private-txt-h">Security of Personal Information:</h6>

            <p className="private-txt-p">
              We take the security of our clients' personal information
              seriously and have implemented various measures to protect it from
              unauthorized access, use, or disclosure. We use secure servers and
              encryption technology to protect our clients' personal
              information, and we restrict access to it to those who need it to
              provide our services.
            </p>

            <h6 className="private-txt-h">Changes to the Privacy Policy:</h6>
            <p className="private-txt-p">
              We may update our privacy policy from time to time to reflect
              changes in our business practices or legal requirements. We will
              notify our clients of any material changes to our privacy policy
              by email or by posting a notice on our website.
            </p>

            <h6 className="private-txt-h">Contact Us:</h6>

            <p className="private-txt-p">
              If you have any questions or concerns about our privacy policy or
              the way we handle your personal information, please contact us at
              info@Dimoora.com.
            </p>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}
