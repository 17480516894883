import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";

const Bestofweek = () => {
  // Define arrays for each row with image URLs and column sizes
  const row1Properties = [
    {
      id: 1,
      imageUrl:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/The-Rings-by-PMR-19-570x370.jpg",
      colSize: 6, // lg={6}
      btn1: "Just sold",
      para1: "Dubai Water Canel,Jumeria",
      btn2: "2800 $",
    },
    {
      id: 2,
      imageUrl: "assets/post-1.jpeg",
      colSize: 3, // lg={3}
      btn1: "", // No "Just Sold" button
      para1: "",
      btn2: "",
    },
    {
      id: 3,
      imageUrl:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-570x370.png",
      colSize: 3, // lg={3}
      btn1: "Just Sold",
      para1: "Dubai Silicon Oasis",
      btn2: "2800 $",
    },
  ];

  const row2Properties = [
    {
      id: 4,
      imageUrl: "assets/post-2.jpeg",
      colSize: 3, // lg={3}
      btn1: "", // No "Just Sold" button
      para1: "",
      btn2: "",
    },
    {
      id: 5,
      imageUrl:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Damac-Sun-City-at-Dubailand-570x370.jpg",
      colSize: 6, // lg={6}
      btn1: "Just Sold",
      para1: "Dubai Water Canel,Jumeria",
      btn2: "2800 $",
    },
    {
      id: 6,
      imageUrl:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Binghatti-Ghost-at-Al-Jaddaf-570x370.jpg",
      colSize: 3, // lg={3}
      btn1: "Just Sold",
      para1: "Dubai Hills Estate",
      btn2: "2800 $",
    },
  ];

  return (
    <>
      <section className="section-1 pb-5 gaps">
        <Container>
          <h2 style={{ color: "#455d58" }}>Best Offers This Week</h2>
          <div>
            {/* First Row */}
            <Row className="equal-height-row pt-2">
              {row1Properties.map((property, index) => (
                <Col
                  xs={12}
                  md={12}
                  lg={property.colSize} // Dynamically set column size
                  key={property.id}
                  className="d-flex"
                >
                  <div
                    className="img-row"
                    style={{
                      backgroundImage: `url(${property.imageUrl})`,
                      backgroundSize: index === 1 ? "contain" : "cover", // Set 'contain' for 1st row, 2nd col
                      backgroundPosition: "center",
                      height: "100%", // Set height for full image coverage
                      minHeight: "300px", // Minimum height to ensure visibility
                      backgroundRepeat: "no-repeat", // Avoid repeating the image
                    }}
                  >
                    <div className="row-offer">
                      <div className="d-flex justify-content-between p-3">
                        {/* Conditionally render "Just Sold" button */}
                        <div>
                          {property.id !== 2 && (
                            <button className="off-btn">{property.btn1}</button>
                          )}
                        </div>
                        {/* Conditionally render flash icon */}
                        {property.id !== 2 && (
                          <div className="flash-div">
                            <i className="fi fi-rs-bolt flash"></i>
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <h3 className="off-txt">{property.para1}</h3>
                        </div>
                        <div>
                          <button className="off-btn-1">{property.btn2}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            {/* Second Row */}
            <Row className="equal-height-row pt-2 mt-4">
              {row2Properties.map((property, index) => (
                <Col
                  xs={12}
                  md={12}
                  lg={property.colSize} // Dynamically set column size
                  key={property.id}
                  className="d-flex"
                >
                  <div
                    className="img-row"
                    style={{
                      backgroundImage: `url(${property.imageUrl})`,
                      backgroundSize: index === 0 ? "contain" : "cover",
                      backgroundPosition: "center",
                      height: "100%", // Set height for full image coverage
                      minHeight: "300px", // Minimum height to ensure visibility
                      backgroundRepeat: "no-repeat", // Avoid repeating the image
                    }}
                  >
                    <div className="row-offer">
                      <div className="d-flex justify-content-between p-3">
                        {/* Conditionally render "Just Sold" button */}
                        <div>
                          {property.id !== 4 && (
                            <button className="off-btn">{property.btn1}</button>
                          )}
                        </div>
                        {/* Conditionally render flash icon */}
                        {property.id !== 4 && (
                          <div className="flash-div">
                            <i className="fi fi-rs-bolt flash"></i>
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <h3 className="off-txt">{property.para1}</h3>
                        </div>
                        <div>
                          <button className="off-btn-1">{property.btn2}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Bestofweek;
