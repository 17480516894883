import React from "react";
import { Col, Container, Row, Carousel, Button } from "react-bootstrap";

export default function Awards() {
  // Array of div content with image URLs
  const divs = [
    {
      id: 1,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Binghatti-Ghost-at-Al-Jaddaf-570x370.jpg",
    },
    {
      id: 2,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Golf-Hillside-at-Dubai-Hills-Estate-570x370.jpg",
    },
    {
      id: 3,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-570x370.png",
    },
    {
      id: 4,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Lavita-at-The-Oasis-5-570x370.jpg",
    },
    {
      id: 5,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Damac-Sun-City-at-Dubailand-570x370.jpg",
    },
    {
      id: 6,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/The-Rings-by-PMR-19-570x370.jpg",
    },

    {
      id: 7,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Damac-Sun-City-at-Dubailand-570x370.jpg",
    },
    {
      id: 8,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/09/Golf-Hillside-at-Dubai-Hills-Estate-570x370.jpg",
    },
  ];

  return (
    <div>
      <section className="product-container pb-5 gaps section-1">
        <Container>
          <div className="d-flex flex-column">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <div>
                  <h2 className="txt-c-1">Community in Trend</h2>
                  <p className="txt-c">
                    Looking for a community that truly feels like home? House &
                    Hedges Real Estate specializes in matching you with
                    neighborhoods that perfectly align with your lifestyle and
                    budget. Whether you're seeking an upscale gated villa or a
                    luxury waterfront property, we ensure a hassle-free
                    experience. Discover your dream community with House &
                    Hedges Real Estate today!
                  </p>
                  <div className="btn-div-m ">
                    <Button className="btn-ani-1" href="/allcities">
                      <span>All Cities</span>
                    </Button>
                  </div>
                </div>
              </Col>

              <Col  sm={12} md={6} lg={6}>
                <div className="community-m">
                  <Carousel
                    indicators={true} // Dots navigation enabled
                    controls={false} // Remove buttons for next/prev
                    interval={2000} // Autoplay interval in ms
                    className="awards-div"
                  >
                    {divs.map((div) =>
                      div.imgSrc ? ( // Ensure empty image is not displayed
                        <Carousel.Item key={div.id} className="side-slide">
                          <img
                            className="d-block w-100 img-awards"
                            src={div.imgSrc}
                            alt={`Slide ${div.id}`}
                          />
                        </Carousel.Item>
                      ) : null
                    )}
                  </Carousel>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
}
