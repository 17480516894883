import React, { useState } from "react";
import Nav from "./Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import Footer from "../FOOTER/Footer";
import NavComponent from "./Navbar";

export default function FindAd() {
  const [startIndex, setStartIndex] = useState(0);
  const [activeButton, setActiveButton] = useState<"next" | "previous" | null>(
    null
  );
  const totalDivs = 2;
  const visibleCount = 1;

  const divs = [
    {
      id: 1,
      imageUrl:
        "https://houseandhedges.ae/wp-content/uploads/2023/04/31-512-300x300.webp",
      title: "Head Office",
      para: "Office - 403 - Park Heights Square 2, Dubai Hills Estate, Dubai",
      phone: "+971 48343543",
      description: "Open Google Map",
    },
    {
      id: 2,
      imageUrl: "https://houseandhedges.ae/wp-content/uploads/2024/08/JVC.png",
      title: "JVC Branch",
      para: "Office A302, Prime Business Center, Jumeirah Village Circle, Dubai",
      phone: "+971 48343543",
      description: "Open Google Map",
    },
  ];

  // Handle the "greater than" button click
  const handleNext = () => {
    setActiveButton("next");
    if (startIndex + visibleCount < totalDivs) {
      setStartIndex(startIndex + 1);
    }
    setTimeout(() => setActiveButton(null), 300);
  };

  // Handle the "less than" button click
  const handlePrevious = () => {
    setActiveButton("previous");
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
    setTimeout(() => setActiveButton(null), 300);
  };

  return (
    <>
      <section className="navbar-section">
        <NavComponent />
      </section>
      <section>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2748.7067658221395!2d55.334499!3d25.251787!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1726558826827!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <section className="pt-5 section">
        <Container>
          <div className="d-flex justify-content-center pb-4">
            <div className="bg-2 fix-contact">
              <h5 className="off-txt-1">Have a questions? Get in touch!</h5>

              <div className="mt-3">
                <div>
                  <label className="mb-2">First Name</label>
                  <Input className="inputs" placeholder="First Name" />
                </div>

                <div>
                  <label className="mb-2">Last Name</label>
                  <Input className="inputs" placeholder="Last Name" />
                </div>

                <div>
                  <label className="mb-2">Email</label>
                  <Input className="inputs" placeholder="Email" />
                </div>

                <div>
                  <label className="mb-2">Message</label>
                  <textarea
                    placeholder="Message"
                    className="inputs-text"
                  ></textarea>
                </div>

                <div className="d-flex justify-content-center mt-3">
                  <Button className="btn-tour">Submit A Request</Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="pt-5">
        <Container>
          <h1 className="ad-txt">Visit Our Office</h1>
          <Row className="pb-5 pt-5">
            <Col sm={12} md={6} lg={7}>
              <div className="d-flex justify-content-center align-items-center gaps-img">
                {/* Less Than Button */}
                <div className="less-div">
                  <button
                    className={`lessthan ${
                      activeButton === "previous" ? "active" : ""
                    }`}
                    onClick={handlePrevious}
                    disabled={startIndex === 0}
                  >
                    <i className="fi fi-rr-angle-small-left d-icons"></i>
                  </button>
                </div>

                {/* Display only the visible divs */}
                <div className="d-flex product-div gap align-items-center">
                  {divs
                    .slice(startIndex, startIndex + visibleCount)
                    .map((div) => (
                      <div key={div.id} className="custom-div">
                        <div className="img-div-ad">
                          <img
                            src={div.imageUrl}
                            alt="img"
                            className="img-fluidm help-img"
                          />
                        </div>
                      </div>
                    ))}
                </div>

                {/* Greater Than Button */}
                <div className="greater-div">
                  <button
                    className={`greaterthan ${
                      activeButton === "next" ? "active" : ""
                    }`}
                    onClick={handleNext}
                    disabled={startIndex + visibleCount >= totalDivs}
                  >
                    <i className="fi fi-rr-angle-small-right d-icons"></i>
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={5}>
              {/* Display only the visible divs */}
              <div className="d-flex product-div-2 gap align-items-center">
                {divs
                  .slice(startIndex, startIndex + visibleCount)
                  .map((div) => (
                    <div key={div.id} className="custom-div d-flex">
                      <div className="content-div text-right ms-3">
                        <h3 className="txt-7-1">{div.title}</h3>
                        <p className="txt-8-2">{div.para}</p>
                        <p className="txt-8-2">{div.phone}</p>
                        <p className="txt-8-3">{div.description}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section p-5">
        <Container>
          <Row>
            <Col lg={7}>
              <div>
                <h4 className="txt-ad-h">Need help? Talk to our expert.</h4>
                <p className="txt-ad-p">
                  Talk to our experts or Browse through more properties.
                </p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="d-flex justify-content-center align-items-start">
                <Button className="btn-ad" href="/contact">
                  Contact us
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
}
