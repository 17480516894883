import React, { useEffect, useState } from "react";
import NavComponent from "../HOME/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import Offcontent from "./Offplan-Content";
import Footer from "../FOOTER/Footer";
import { data } from "../Data";
import { useParams } from "react-router-dom";

export default function Offplan() {
  const [offData, setOffData] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) return;

    const filteredData = data.filter((val) => val.id.toString() === id);
    if (filteredData.length > 0) {
      setOffData(filteredData);
    }
  }, [id]);

  // Helper function to chunk array into smaller arrays
  const chunkArray = (arr: any[], chunkSize: number) => {
    const result: any[] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  // Since you want exactly two rows, we only chunk into 2 groups.
  const imageChunks = chunkArray(offData[0]?.image || [], 3);

  // Function to move slideshow
  const moveSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageChunks.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(moveSlide, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [imageChunks.length]);

  return (
    <>
      <NavComponent />
      <section className="section section-3">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div>
                <h2 className="txt-4">
                  {offData[0]?.title || "No title available"}
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="d-flex gap-4 mt-2 location">
                <div className="txt-4">
                  {offData[0]?.location || "No location available"}
                </div>
                <div className="txt-4">
                  {offData[0]?.category
                    ? offData[0].category.toUpperCase()
                    : "No category"}
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="d-flex justify-content-end">
                <div className="d-flex gap-4">
                  <div>
                    <i className="fi fi-rs-heart icon-off"></i>
                  </div>
                  <div>
                    <i className="fi fi-rs-multiple icon-off"></i>
                  </div>
                  <div>
                    <i className="fi fi-rs-share icon-off"></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="pt-4 gaps-row">
            {imageChunks[currentIndex]?.map((imgSrc: string, index: number) => (
              <Col lg={4} md={6} sm={12} key={index}>
                <div className="off-plan-img">
                  <img
                    src={imgSrc}
                    alt={`offplan-img-${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </Col>
            ))}
          </Row>

          <Offcontent data={offData} />
        </Container>
      </section>

      <Footer />
    </>
  );
}
