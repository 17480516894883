import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { useInView } from "react-intersection-observer";

export default function Achive() {
  const [counts, setCounts] = useState({
    developers: 0,
    projects: 0,
    clients: 0,
  });

  const { ref: sectionRef, inView } = useInView({
    threshold: 0.5, // 50% of the element is in view
    triggerOnce: true, // Trigger only once when the section comes into view
  });

  useEffect(() => {
    if (inView) {
      const duration = 2000; // Duration for the animation (2 seconds)
      const increment = (target: number, key: keyof typeof counts) => {
        let start = 0;
        const stepTime = Math.abs(Math.floor(duration / target));

        const timer = setInterval(() => {
          start += 1;
          setCounts((prevCounts) => ({
            ...prevCounts,
            [key]: start,
          }));
          if (start === target) clearInterval(timer);
        }, stepTime);
      };

      increment(24, "developers");
      increment(90, "projects");
      increment(200, "clients");
    }
  }, [inView]);

  return (
    <>
      <section className="section-bg" ref={sectionRef}>
        <Container>
          <div className="pt-lg-5">
            <h2 className="txt-1-a">Our Achievement</h2>
            <div>
              <Row className="row-achive">
                <Col lg={4} md={4} sm={4}>
                  <div className="achive-bg">
                    <div>
                      <img
                        src="https://www.luxuryresidences.in/images/home/developer-icon.png"
                        alt="img" className="img-achive"
                      />
                    </div>
                    <h1 className="a-txt">{counts.developers}</h1>
                    <p className="a-txt-1">Developers</p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <div className="achive-bg">
                    <div>
                      <img
                        src="https://www.luxuryresidences.in/images/home/project-icon.png"
                        alt="img" className="img-achive"
                      />
                    </div>
                    <h1 className="a-txt">{counts.projects}</h1>
                    <p className="a-txt-1">Projects</p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <div className="achive-bg">
                    <div>
                      <img
                        src="https://www.luxuryresidences.in/images/home/client-icon.png"
                        alt="img" className="img-achive"
                      />
                    </div>
                    <h1 className="a-txt">{counts.clients}</h1>
                    <p className="a-txt-1">Clients</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
