import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavComponent from "../HOME/Navbar";
import { Input } from "antd";
import { Box, Modal, Radio } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Footer from "../FOOTER/Footer";
import axios from "axios";
import { Link } from "react-router-dom";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Contact() {
  const [selectedValue, setSelectedValue] = useState("buy");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({
    name: "",
    phone: "",
    mail: "",
    message: "",
    option: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    setData({ ...data, option: value }); 
  };

  const handleBooking = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !data.name ||
      !data.phone ||
      !data.mail ||
      !data.message ||
      !data.option
    ) {
      alert("Please fill out all fields");
      return;
    }
    setLoading(true);
    try {
      await axios.post("https://dimooraapi.onrender.com/ContactUs", {
        name: data.name,
        userPhonenumber: data.phone,
        userEmail: data.mail,
        option: data.option,
        message: data.message,
      });
      setData({
        name: "",
        phone: "",
        mail: "",
        option: "",
        message: "",
      });
    setOpen(true)
    } catch (err: any) {
      console.error(err.message);
      alert("Failed to submit. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Navbar Section */}
      <section className="navbar-section">
        <NavComponent />
      </section>
      <section className="section-3 bg-3">
        <Container>
          <div className="cnt-div">
            <div className="d-flex justify-content-between gap-5">
              <div className="contact-form">
                <h5 className="txt-form">Get in Touch with us</h5>
                <Input
                  className="form"
                  placeholder="Name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <Input
                  className="form"
                  placeholder="Email"
                  value={data.mail}
                  onChange={(e) => setData({ ...data, mail: e.target.value })}
                />
                <Input
                  className="form"
                  placeholder="Phone"
                  value={data.phone}
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                />
                <div className="d-flex justify-content-start buy">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio-group-label"
                      name="row-radio-buttons-group"
                      value={data.option}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="buy"
                        control={<Radio />}
                        label="Buy"
                        className="radios"
                      />
                      <FormControlLabel
                        value="sell"
                        control={<Radio />}
                        label="Sell"
                        className="radios"
                      />
                      <FormControlLabel
                        value="rent"
                        control={<Radio />}
                        label="Rent"
                        className="radios"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <textarea
                  className="txt-area"
                  placeholder="Message"
                  value={data.message}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                ></textarea>
                <div className="d-flex justify-content-center">
                  <Button className="form-btn" onClick={handleBooking}>
                    {loading ? "Submitting..." : "Book A Call"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div className="pb-3 pt-5">
            <Row>
              <Col lg={4}>
                <div>
                  <div className="ctn-txt">
                    <i className="fi fi-rs-call-outgoing"></i>
                    <p>(844) 758-9890</p>
                  </div>
                  <p className="txt-ctn">
                    Talk to a Customer Service Representative for help with our
                    site, app, or finding a Redfin Agent.
                  </p>
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <div className="ctn-txt">
                    <i className="fi fi-rs-envelope"></i>
                    <p>Messages</p>
                  </div>
                  <p className="txt-ctn">
                    Send our Customer Service Team questions about our site,
                    app, or finding a Redfin Agent.
                  </p>
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <div className="ctn-txt">
                    <i className="fi fi-rs-call-outgoing"></i>
                    <p>Connect with us</p>
                  </div>
                  <div className="d-flex justify-content-center gap-3">
                    <div>
                      <a href="https://www.facebook.com/profile.php?id=61565695876899">
                        <i className="fi fi-brands-facebook icon-off-1"></i>
                      </a>
                    </div>
                    <div>
                      <a href="https://x.com/dimoora68987">
                        <i className="fi fi-brands-twitter-alt icon-off-1"></i>
                      </a>
                    </div>
                    <div>
                      <a href="https://www.youtube.com/@Dimoora">
                        <i className="fi fi-brands-youtube icon-off-1"></i>
                      </a>
                    </div>
                    <div>
                      <a href="https://www.instagram.com/dimoora_property_sales/">
                        <i className="fi fi-brands-instagram icon-off-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="text-center">
            <img
              className="img-fluid"
              style={{ width: "100px" }}
              src="/assets/certificate.gif"
            />
          </div>
          <h3 className="text-center mb-0">
            Your message has been sent successfully.
          </h3>
          <h3 className="text-center mt-0">We will reach you out soon...</h3>
          <div className="text-end"><Link to="/"><Button className="continue" >Continue ..</Button></Link></div>
        </Box>
      </Modal>
    </>
  );
}
