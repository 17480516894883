import React, { useEffect, useState } from "react";
import Nav from "../HOME/Navbar";
import { Container } from "react-bootstrap";
import { Card } from "antd";
import Footer from "../FOOTER/Footer";
import { useNavigate } from "react-router-dom";
import { data } from "../Data";
import NavComponent from "../HOME/Navbar";

// Define the Deal interface
interface Deal {
  id: number;
  image: string[]; // Updated to an array as per usage
  title: string;
  location: string;
  category: string;
}

export default function CurrentPlan() {
  const navigate = useNavigate();
  const [datas, setDatas] = useState<Deal[]>([]);

  useEffect(() => {
    // Filtering data based on category
    const filteredData = data.filter(
      (val: Deal) => val.category === "ready to move"
    );
    setDatas(filteredData);
  }, []);

  const handleNav = (id: number) => {
    navigate(`/readytomove/${id}`);
  };

  return (
    <>
      <NavComponent />
      <section className="gaps-3 section pb-5">
        <Container>
          <div>
            <h2 style={{ color: "white" }}>Ready to Move Plan</h2>
            <div className="row on-plan-row">
              {datas.map((deal) => (
                <div key={deal.id} className="col-lg-4 col-md-6 off">
                  <Card
                    className="filter-card"
                    cover={
                      <img
                        src={deal.image[0]}
                        alt={deal.title}
                        className="img-fluid img-proper"
                      />
                    }
                  >
                    <h6
                      className="txt-f"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNav(deal.id)}
                    >
                      {deal.title}
                    </h6>
                    <p className="txt-f-1">{deal.location}</p>
                    <div className="d-flex justify-content-between">
                      <p>{deal.category}</p>
                      <div className="d-flex gap-3 ">
                        <div>
                          <a href="https://www.facebook.com/profile.php?id=61565695876899">
                            <i className="fi fi-brands-facebook  f-icon"></i>
                          </a>
                        </div>
                        <div>
                          <a href="https://x.com/dimoora68987">
                            <i className="fi fi-brands-twitter-alt f-icon"></i>
                          </a>
                        </div>
                        <div>
                          <a href="https://www.youtube.com/@Dimoora">
                            <i className="fi fi-brands-youtube f-icon"></i>
                          </a>
                        </div>
                        <div>
                          <a href="https://www.instagram.com/dimoora_property_sales/">
                            <i className="fi fi-brands-instagram f-icon"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}
