import React, { useEffect, useState } from "react";
import Nav from "./Navbar";
import { Col, Container, Row } from "react-bootstrap";
import type { GetProps } from "antd";
import { Button, Card, Checkbox, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import type { GetProp } from "antd";
import { Input } from "antd";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { Select } from "antd";
import { data } from "../Data";
import Footer from "../FOOTER/Footer";
import { Menu, MenuItem } from "@mui/material";
import NavComponent from "./Navbar";

const MAX_VALUE = 129999996;
const MIN_VALUE = 0;

const marks = [
  {
    value: MIN_VALUE,
    label: "",
  },
  {
    value: MAX_VALUE,
    label: "",
  },
];

// Function to format numbers as currency
const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "AED" }).format(
    value
  );

export default function Allcity() {

  const {search}=useParams()
  console.log(search);


  

  const [ogData, setOgdata] = useState(data);
  const [showAmenities, setShowAmenities] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = React.useState<string>("Default");
  const [datas, setDatas] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState<string>("");
  const [selectedBaths, setSelectedBaths] = useState<string>("");
  const [selectedLoc, setSelectedLoc] = useState<string>("");
  const [isSearch, setIsSearch] = useState("");

  type SearchProps = GetProps<typeof Input.Search>;

  const { Search } = Input;

  const onSearch = (value: any) => {
    setIsSearch(value);
    console.log(value);
    let d = ogData.filter(
      (val) =>
        val?.location.includes(value) ||
        val.title.includes(value) ||
        val.category.trim().includes(value)
    );
    setDatas(d);
  };


  useEffect(() => {
    setIsSearch(search || "");
    const filteredProperties = ogData.filter((val) => {
      const searchTerm = search || ""; 
      return (
          val?.location.includes(searchTerm) ||
          val.title.includes(searchTerm) ||
          val.category.trim().includes(searchTerm)
      );
  });
  setDatas(filteredProperties);
  
}, [search]); 


  const filteredProperties = data.filter((property) => {
    if (selectedBedrooms) {
      return property.bed === selectedBedrooms;
    }
    return true;
  });

  useEffect(() => {
    setDatas(filteredProperties);
  }, [selectedBedrooms, ogData]);

  // baths

  const filteredbaths = data.filter((property) => {
    if (selectedBaths) {
      return property.baths === selectedBaths;
    }
    return true;
  });

  useEffect(() => {
    setDatas(filteredbaths);
  }, [selectedBaths, ogData]);

  // location

  const filteredloc = data.filter((property) => {
    if (selectedLoc) {
      return property.optionl === selectedLoc;
    }
    return true;
  });

  useEffect(() => {
    setDatas(filteredloc);
  }, [selectedLoc, ogData]);

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (
    checkedValues: any
  ) => {
    setSelectedValues(checkedValues);

    let checkedvalue = checkedValues[0];
    let d = ogData.filter(
      (val) =>
        val.propertystatus === checkedvalue ||
        val.propertytype === checkedvalue ||
        val.features.some((feature) => checkedValues.includes(feature))
    );
    setDatas(d);
  };

  const options = [
    { label: "For Rent", value: "for rent" },
    { label: "For Sell", value: "for sell" },
    { label: "For Buy", value: "for buy" },
  ];

  const propertytype = [
    { label: "Apartments", value: "apartments" },
    { label: "Studio", value: "studio" },
    { label: "Bungalow", value: "bungalow" },
    { label: "Duplexes", value: "duplexes" },
    { label: "Farmhouse", value: "farmhouse" },
    { label: "Houses", value: "houses" },
    { label: "Loft", value: "loft" },
    { label: "Mansions", value: "mansions" },
    { label: "Office", value: "office" },
    { label: "Penthouses", value: "penthouses" },
    { label: "Residential Tower", value: "residential Tower" },
    { label: "Simplexes", value: "simplexes" },
    { label: "Sky Palaces", value: "sky Palaces" },
    { label: "Townhome", value: "townhome" },
    { label: "Townhouses", value: "townhouses" },
    { label: "Villa", value: "villa" },
  ];

  const amenities = [
    { label: "Air Conditioning", value: "air conditioning" },
    { label: "Balcony", value: "balcony" },
    { label: "Barbeque", value: "barbeque" },
    { label: "Basement parking", value: "basement parking" },
    { label: "Buit in wardoes", value: "buit in wardoes" },
    { label: "Children play's area", value: "children play area" },
    { label: "Covered parking", value: "covered parking" },
    { label: "Dryer", value: "dryer" },
    { label: "Fully fited chicken", value: "fully fited chicken" },
    { label: "Gym", value: "gym" },
    { label: "Lawn", value: "lawn" },
    { label: "Microwave", value: "microwave" },
    { label: "Near hospital", value: "near hospital" },
    { label: "Near mall", value: "near mall" },
    { label: "Near public transportion", value: "near public transportion" },
    { label: "Near restaurents", value: "near restaurents" },
    { label: "Outdoor Shower", value: "outdoor shower" },
    { label: "Public parks", value: "public parks" },
    { label: "Refrigerator", value: "refrigerator" },
    { label: "Security", value: "security" },
    { label: "Swimming Pool", value: "swimming pool" },
    { label: "TV Cable", value: "tv cable" },
    { label: "Washer", value: "washer" },
    { label: "WiFi", value: "wifi" },
  ];

  const handleToggleAmenities = () => {
    setShowAmenities((prev) => !prev);
  };

  const [val, setVal] = useState<number[]>([MIN_VALUE, MAX_VALUE]);

  const handleChange = (_: Event, newValue: number | number[]) => {
    const valueArray = Array.isArray(newValue) ? newValue : [newValue];

    setVal(valueArray as number[]);
    console.log("Slider Value:", valueArray);
    const filteredData = ogData.filter((item) => {
      const price = item.prices;
      return price >= valueArray[0] && price <= valueArray[1];
    });

    setDatas(filteredData);
  };

  const navigate = useNavigate();
  const handleNav = (id: any) => {
    navigate(`/offplan/${id}`);
  };

  useEffect(() => {
    const d = data.filter((val) => val.category === "off plan");
    setDatas(d);
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string) => {
    setSelectedOption(option);
    let filteredData;
    if (option === "Highest Price") {
      filteredData = ogData.sort((a, b) => b.prices - a.prices);
    } else if (option === "Lowest Price") {
      filteredData = ogData.sort((a, b) => a.prices - b.prices);
    } else {
      filteredData = ogData;
    }

    setDatas(filteredData);
    handleClose();
  };

  const resetFilters = () => {
    setDatas(ogData);
    setSelectedValues([]);
    setSelectedBedrooms("");
    setSelectedBaths("");
    setSelectedLoc("");
    setIsSearch("");
  };

  return (
    <>
      <section className="navbar-section">
        <NavComponent />
      </section>

      <section className="section pb-5 top-all">
        <Container>
          <h1 style={{ color: "white", paddingTop: "30px" }}>Properties</h1>

          <Row>
            <Col lg={4} md={4} sm={12}>
              <div className="pt-5 filter-all">
                <Row>
                  <Col>
                    <div className="home-bg">
                      <h5 className="find-h">Find your home</h5>
                      <Search
                        placeholder="What are you looking for?"
                        value={isSearch}
                        onSearch={onSearch}
                        onChange={(e) => setIsSearch(e.target.value)}
                        style={{ width: "100%", flex: 1 }}
                        enterButton={false}
                      />

                      <div className="pt-3">
                        <h5 className="find-h">Property Status</h5>
                        <Checkbox.Group
                          options={options}
                          onChange={onChange}
                          className="find-a"
                          value={selectedValues}
                          style={{ display: "flex", flexDirection: "column" }}
                        />
                      </div>

                      <div className="pt-3">
                        <h5 className="find-h">Property Type</h5>
                        <Checkbox.Group
                          options={propertytype}
                          value={selectedValues}
                          className="find-a"
                          onChange={onChange}
                          style={{ display: "flex", flexDirection: "column" }}
                        />
                      </div>

                      <div className="pt-3">
                        <h5 className="find-h">Price Range</h5>
                        <Slider
                          marks={marks}
                          step={1000}
                          value={val} // Ensure val is an array
                          valueLabelDisplay="auto"
                          min={MIN_VALUE}
                          max={MAX_VALUE}
                          onChange={handleChange}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="body2"
                            onClick={() => setVal([MIN_VALUE, val[1]])} // Keep the current max
                            sx={{ cursor: "pointer" }}
                          >
                            {formatCurrency(MIN_VALUE)}
                          </Typography>

                          <Typography
                            variant="body2"
                            onClick={() => setVal([val[0], MAX_VALUE])} // Keep the current min
                            sx={{ cursor: "pointer" }}
                          >
                            {formatCurrency(MAX_VALUE)}
                          </Typography>
                        </Box>
                      </div>

                      <div className="pt-3">
                        <h5 className="find-h">Bedrooms</h5>
                        <Select
                          showSearch
                          placeholder="Bedrooms:any"
                          className="switch-h"
                          value={selectedBedrooms}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) =>
                            setSelectedBedrooms(value ? value : "")
                          }
                          options={[
                            { value: "1+", label: "1+" },
                            { value: "2+", label: "2+" },
                            { value: "3+", label: "3+" },
                            { value: "4+", label: "4+" },
                            { value: "5+", label: "5+" },
                          ]}
                        />
                      </div>

                      <div className="pt-3">
                        <h5 className="find-h">Baths</h5>
                        <Select
                          showSearch
                          placeholder="Baths:any"
                          value={selectedBaths}
                          className="switch-h"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) =>
                            setSelectedBaths(value ? value : "")
                          }
                          options={[
                            { value: "1+", label: "1+" },
                            { value: "2+", label: "2+" },
                            { value: "3+", label: "3+" },
                            { value: "4+", label: "4+" },
                            { value: "5+", label: "5+" },
                          ]}
                        />
                      </div>

                      <div className="pt-3">
                        <h5 className="find-h">Location</h5>
                        <Select
                          value={selectedLoc}
                          showSearch
                          placeholder="Filter by Country"
                          className="switch-h"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) =>
                            setSelectedLoc(value ? value : "")
                          }
                          options={[
                            { value: "ajman", label: "Ajman" },
                            { value: "ai barsha", label: "Ai Barsha" },
                            { value: "dubai", label: "Dubai" },
                            {
                              value: "dubai hillestate",
                              label: "Dubai hillestate",
                            },
                            {
                              value: "ras al khaimah",
                              label: "Ras al khaimah",
                            },
                          ]}
                        />
                      </div>

                      <div className="pt-3">
                        <div
                          className="d-flex gap-2"
                          onClick={handleToggleAmenities}
                          style={{ cursor: "pointer" }}
                        >
                          <div>
                            <i className="fi fi-rs-bars-staggered"></i>
                          </div>
                          <h5 className="find-h">other features</h5>
                        </div>

                        {showAmenities && (
                          <div>
                            <h5 className="find-h">Amenities</h5>
                            <Checkbox.Group
                              options={amenities}
                              value={selectedValues}
                              onChange={onChange}
                              className="find-a"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-between pt-3">
                        <div className="d-flex s-txt">
                          <div>
                            <i className="fi fi-rs-rotate-reverse"></i>
                          </div>
                          <div
                            className="s-txt-d"
                            style={{ cursor: "pointer" }}
                            onClick={resetFilters}
                          >
                            Reset search
                          </div>
                        </div>
                        <div className="d-flex s-txt d-none">
                          <div>
                            <i className="fi fi-rs-star"></i>
                          </div>
                          <div className="s-txt-d">Save Search</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={8} md={8} sm={12}>
              <div className="row g-3 pt-4">
                <div className="d-flex justify-content-end gap-2">
                  <p className="d-flex align-items-center sort-txt">Sort by:</p>
                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {selectedOption} <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {/* <MenuItem onClick={() => handleMenuItemClick("Oldest")}>
                        Oldest
                      </MenuItem> */}
                      {/* <MenuItem onClick={() => handleMenuItemClick("Newest")}>
                        Newest
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => handleMenuItemClick("Highest Price")}
                      >
                        Highest Price
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleMenuItemClick("Lowest Price")}
                      >
                        Lowest Price
                      </MenuItem>
                      {/* <MenuItem onClick={() => handleMenuItemClick("Random")}>
                        Random
                      </MenuItem> */}
                    </Menu>
                  </div>
                </div>
                {/* Added Bootstrap g-3 class for column and row gaps */}
                {datas.map((deal: any) => (
                  <div className="col-lg-6 col-sm-12 text-row" key={deal?.id}>
                    {" "}
                    {/* 2-column layout */}
                    <div>
                      <Card
                        className="filter-card"
                        cover={
                          <img
                            src={deal?.image[0]}
                            className="img-fluid img-proper"
                          />
                        }
                      >
                        <h6
                          className="txt-f"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleNav(deal.id)}
                        >
                          {deal?.title}
                        </h6>
                        <p className="txt-f-1">{deal?.location}</p>
                        <div className="d-flex justify-content-between">
                          <p>{deal?.category}</p>
                          <div className="d-flex gap-3 ">
                            <div>
                              <a href="https://www.facebook.com/profile.php?id=61565695876899">
                                <i className="fi fi-brands-facebook  f-icon"></i>
                              </a>
                            </div>
                            <div>
                              <a href="https://x.com/dimoora68987">
                                <i className="fi fi-brands-twitter-alt f-icon"></i>
                              </a>
                            </div>
                            <div>
                              <a href="https://www.youtube.com/@Dimoora">
                                <i className="fi fi-brands-youtube f-icon"></i>
                              </a>
                            </div>
                            <div>
                              <a href="https://www.instagram.com/dimoora_property_sales/">
                                <i className="fi fi-brands-instagram f-icon"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                ))}
                {datas.length <= 0 && (
                  <p className="text-center fs-4" style={{ color: "#fff" }}>
                    No properties found !
                  </p>
                )}
              </div>
              <div className="mt-5 page d-none">
                <Pagination align="center" defaultCurrent={1} total={50} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
