import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../HOME/Home";
import Products from "../PROJECTS/Projects";
import Contact from "../CONTACT/Contact";
import Projects from "../PROJECTS/Projects";
import Offplan from "../PROJECTS/Offplan";
import Help from "../HOME/Help";
import Ourservice from "../OURSERVICE/Ourservice";
import Fillter from "../PROJECTS/Offproperties";
import Filter from "../PROJECTS/Offproperties";
import Onplan from "../PROJECTS/Onplan";
import Current from "../PROJECTS/Current";
import Findhome from "../HOME/Properties";
import FindAd from "../HOME/Adproperties";
import Findrental from "../HOME/Rental";
import Allcities from "../HOME/Allcities";
import Allcity from "../HOME/Allcities";
import Careers from "../FOOTER/Careers";
import Privacypolicy from "../FOOTER/Privacypolicy";
import Termsofuse from "../FOOTER/Termsofuse";
import ScrollToTop from "./Scrolltop";
import { data } from "../Data";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/offplan/:id" element={<Offplan />}></Route>
          <Route path="/onplan/:id" element={<Offplan />}></Route>
          <Route path="/readytomove/:id" element={<Offplan />}></Route>
          <Route path="/offplan" element={<Filter />}></Route>
          <Route path="/onplan" element={<Onplan />}></Route>
          <Route path="/currentplan" element={<Current />}></Route>
          <Route path="/ourservice" element={<Ourservice />}></Route>
          <Route path="/help-section" element={<Help />}></Route>
          <Route path="/find-home" element={<Findhome />}></Route>
          <Route path="/place-ad" element={<FindAd />}></Route>
          <Route path="/rent-property" element={<Findrental />}></Route>
          <Route path="/allcities" element={<Allcity/>}></Route>
          <Route path="/allcities/:search" element={<Allcity/>}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/termsofuse" element={<Termsofuse />}></Route>
          <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
